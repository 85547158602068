import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import {TextWithSymbols} from "components/stateless";
import React from "react";
import {Link} from "react-router-dom";
import "styles/ClickableTable.css";
import {capitalize, orderBy, timeSince} from "utils";


export default class ClickableTable extends React.Component {

    state = {
        sortBy: this.props.sortBy,
        ascending: this.props.ascending
    };

    constructor(props) {
        super(props);
        this.orderBy = this.orderBy.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.data !== prevProps.data) this.render();
    }

    orderBy(prop) {
        let curProp = this.state.sortBy;
        let asc = this.state.ascending;
        if (curProp === prop) this.setState({ascending: !asc});
        else this.setState({
            sortBy: prop,
            ascending: true
        });
    }

    render() {
        let list = orderBy(this.props.data, this.state.sortBy, this.state.ascending);
        return (
            <div className='CTable w-100 d-table'>
                {this.renderHeader()}
                {list.map(e => this.renderRow(e))}
            </div>
        );
    }

    renderHeader() {
        return (
            <div className='CTHeader d-table-row border-bottom'>
                {this.props.headers.map((h) => {
                    return (
                        h.sortable ?
                            <div className='CTSort d-table-cell text-info p-2 pointer' key={h.name}
                                 onClick={() => this.orderBy(h.name)}>
                                {h.label}
                                <FAI icon="caret-down" className="ml-2"/>
                            </div>
                            :
                            <div className='CTCell d-table-cell p-2' key={h.name}>{h.label}</div>
                    )
                }, this)}
            </div>
        );
    }

    renderRow(e) {
        return (
            <Link className='CTRow d-table-row border-bottom pointer' to={this.props.baseURL + e.id} key={e.id}>
                {this.props.headers.map((h) => {
                    let rawValue = e[h.name];
                    let content = null;
                    switch (h.type) {
                        case 'symbols':
                            content = <TextWithSymbols text={rawValue}/>;
                            break;
                        case 'capitalize':
                            content = capitalize(rawValue);
                            break;
                        case 'timeSince':
                            content = timeSince(new Date(rawValue)) + ' ago';
                            break;
                        default:
                            content = rawValue;
                    }
                    return (
                        <div className='CTCell d-table-cell p-2' key={e.id + '-' + h.name}>{content}</div>
                    )
                })}
                {this.props.delete &&
                <div className='CTCell d-table-cell p-2'>
                    <button className='btn btn-danger' title='Delete' onClick={() => this.props.onDelete(e.id)}>
                        <FAI icon="trash-alt"/>
                    </button>
                </div>
                }
            </Link>
        );
    }
}
