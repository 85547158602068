import React from "react";
import "styles/DeckCard.css";
import CardImage from "./CardImage";

export default class DeckCard extends React.Component {

    state = {
        imgHasLoaded: false
    };

    constructor(props) {
        super(props);
        this.onLeftClick = this.onLeftClick.bind(this);
        this.onRightClick = this.onRightClick.bind(this);
    }

    onLeftClick() {
        this.props.onCardClick(true);
    }

    onRightClick(e) {
        e.preventDefault();
        this.props.onCardClick(false);
    }

    render() {
        let card = this.props.card;

        let style = {
            position: "absolute",
            top: 50 * this.props.index + "px"
        };

        let notEnough = card.total < this.props.amount ? ' NotEnough' : '';

        return (
            <div className={'pointer DeckCard'+notEnough} style={style}
                 onClick={this.onLeftClick} onContextMenu={this.onRightClick}>
                <CardImage name={card.name} set={card.lastPrint.set} number={card.lastPrint.number}/>
            </div>
        );
    }
}