import Chart from "chart.js";
import {PercentageBar, ProgressBar, ProgressCircle} from "components/stateless";
import {colors} from "config/theme";
import React from "react";
import {api, brightenColor} from "utils";
import MultiList from "./MultiList";

export default class SetStats extends React.Component {

    state = {
        statistics: null,
        sets: null,
        doughnutChart: null
    };

    constructor(props) {
        super(props);
        this.listChange = this.listChange.bind(this);
    }

    componentDidMount() {
        api("GET", '/lists', {categories: ['sets']}, ({status, data}) => {
            let content = {
                sets: {
                    list: data.sets.sort((a, b) => a.label > b.label),
                    selected: []
                }
            };
            this.setState(content, this.fetchStatistics);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.amount !== prevProps.amount) this.fetchStatistics();
    }

    fetchStatistics() {
        this.setState({statistics: null});

        // Prepare data
        let form = {
            amount: this.props.amount,
            sets: this.state.sets.selected.map(e => e.code)
        };

        // Send the request
        api("GET", '/statistics/sets', form, ({status, data}) => {
            this.setState({statistics: data}, this.renderPieChart);
        });
    }

    listChange(field, value) {
        field = this.state[field];
        field.selected = value;
        this.setState({[field.name]: field});
        this.fetchStatistics();
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    {this.renderFilters()}
                    <div className="my-3">
                        <canvas id="DoughnutChart"/>
                    </div>
                </div>
                <div className="col-md-6">
                    {this.renderStatsByRarity()}
                </div>
            </div>
        );
    }

    renderStatsByRarity() {
        return (
            <div>
                <h2 className="my-3">By rarity</h2>
                {!this.state.statistics ? (
                    <div className="my-2 d-flex justify-content-center">
                        <ProgressCircle color="#00AAAA" size="100"/>
                    </div>
                ) : (
                    <div>
                        {this.state.statistics.byRarity.map(r => {
                            let color = brightenColor(colors.rarity[r.code.substring(0, 1)], 100);
                            return (
                                <div className="row" key={r.code}>
                                    <div className="col-sm-4">{r.label}</div>
                                    <div className="col-sm-8">
                                        <PercentageBar amount={r.amount} total={r.total}
                                                       color={color}
                                                       displayAsAmount={this.props.display === 'amount'}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>)}
            </div>
        );
    }

    renderPieChart() {
        let ctx = document.getElementById("DoughnutChart");
        let amount = this.state.statistics.total.amount;
        let total = this.state.statistics.total.total;
        let amountName = this.props.amount === "1" ? 'copy' : 'playset';

        let data = {
            labels: [
                "Prints I have a " + amountName + " of",
                "Prints I don't have a " + amountName + " of",
            ],
            datasets: [{
                data: [amount, total - amount],
                backgroundColor: [
                    'rgba(253,216,53,0.2)',
                    'rgba(189,189,189,0.2)'
                ],
                borderColor: [
                    'rgba(253,216,53,1)',
                    'rgba(189,189,189,1)'
                ],
                borderWidth: 1
            }]
        };

        if (!this.doughnutChart) {
            let chart = new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: {
                    legend: {display: false}
                }
            });

            this.doughnutChart = chart;
        } else {
            this.doughnutChart.data = data;
            this.doughnutChart.update();
        }
    }

    renderFilters() {
        return (
            <div>
                <h2>Refine search to</h2>
                <div className="my-2">
                    {!this.state.sets ? <ProgressBar/> :
                        <MultiList list={this.state.sets.list}
                                   selected={this.state.sets.selected}
                                   placeholder={"all sets"}
                                   onSelectionChange={data => this.listChange("sets", data)}/>}
                </div>
            </div>
        );
    }
}