import DeckCard from "components/DeckCard";
import {ProgressBar, Tabs} from "components/stateless";
import AppLayout from "layouts/AppLayout";
import React from "react";
import DeckCardSearch from "tabs/DeckCardSearch";
import DeckExport from "tabs/DeckExport";
import DeckImport from "tabs/DeckImport";
import DeckInfo from "tabs/DeckInfo";
import DeckStats from "tabs/DeckStats";
import {api, orderBy} from "utils";

export default class Deck extends React.Component {

    state = {
        deck: null,
        cards: [],
        searchResults: [],
        activeTab: "deckInfo",
        activeGroup: "main"
    };

    constructor(props) {
        super(props);
        this.getDeck = this.getDeck.bind(this);
    }

    componentDidMount() {
        this.getDeck();
    }

    getDeck() {
        let id = this.props.match.params.id;
        api("GET", "/decks/" + id, {}, ({status, data}) => {
            this.setState({cards: data.cards});
            delete data.cards;
            this.setState({deck: data});
        });
    }

    addRemoveCard(card, add = true) {
        let sideboard = this.state.activeGroup === "sideboard";
        let cards = this.state.cards;
        let cardExists = false;
        let newAmount = 1;

        let newCards = cards.map(cid => {
            if (cid.card.id === card.id && cid.sideboard === sideboard) {
                cid.amount = add ? cid.amount + 1 : cid.amount - 1;
                newAmount = cid.amount;
                cardExists = true;
            }
            return cid;
        }).filter(cid => cid.amount > 0);
        if (!cardExists) {
            newCards.push({
                amount: 1,
                card: card,
                sideboard: sideboard
            });
        }
        this.setState({cards: newCards});

        // Send the update
        let url = "/decks/" + this.state.deck.id + "/cards/" + card.id;
        api("PATCH", url, {amount: newAmount, sideboard: sideboard ? 1 : 0}, ({status, data}) => {
        });
    }

    getCardsTotal(sideboard) {
        let cards = this.state.cards;
        let total = 0;
        for (let cid of cards) {
            if (cid.sideboard === sideboard) total += cid.amount;
        }
        return total;
    }

    render() {
        if (!this.state.deck) return <ProgressBar/>;

        let tabs = [
            {label: "Info", code: "deckInfo", icon:"info-circle"},
            {label: "Card search", code: "cardSearch", icon:"search"},
            {label: "Statistics", code: "statistics", icon:"chart-pie"},
            {label: "Export", code: "export", icon:"file-export"},
            {label: "Import", code: "import", icon:"file-import"}
        ];

        let groups = [
            {label: "Main deck (" + this.getCardsTotal(false) + " cards)", code: "main"},
            {label: "Sideboard (" + this.getCardsTotal(true) + " cards)", code: "sideboard"}
        ];

        return (
            <AppLayout history={this.props.history}>
                <Tabs entries={tabs} activeTab={this.state.activeTab}
                      onTabClick={(code) => this.setState({activeTab: code})}/>
                {this.renderActiveTab()}
                <hr/>
                <Tabs entries={groups} activeTab={this.state.activeGroup}
                      onTabClick={code => this.setState({activeGroup: code})}/>
                <div className="row">
                    <div className="col-12">
                        {!this.state.cards ? <ProgressBar/> :
                            this.state.cards.length ? this.renderCards() :
                                <h4 className="text-center">
                                    No cards yet. Add them from the search form above!
                                </h4>
                        }
                    </div>
                </div>
            </AppLayout>
        );
    }

    renderActiveTab() {
        return(
            <div className="row">
                <div className="col-12 mb-3">
                    {this.state.activeTab === "deckInfo" &&
                    <DeckInfo deck={this.state.deck} history={this.props.history}
                              onDeckUpdate={deck => this.setState({deck: deck})}/>}

                    {this.state.activeTab === "cardSearch" &&
                    <DeckCardSearch format={this.state.deck.format}
                                    onAddCard={card => this.addRemoveCard(card, true)}/>}

                    {this.state.activeTab === "statistics" &&
                    <DeckStats cards={this.state.cards}/>}

                    {this.state.activeTab === "export" &&
                    <DeckExport cards={this.state.cards}/>}

                    {this.state.activeTab === "import" &&
                    <DeckImport deckId={this.state.deck.id} onImport={this.getDeck}/>}
                </div>
            </div>
        );
    }

    renderCards() {
        let sideboard = this.state.activeGroup === "sideboard";
        let cids = this.state.cards;

        // Filter by sideboard or not
        cids = cids.filter(cid => cid.sideboard === sideboard);

        // Structure the cards by cmc
        let cmcs = [];
        for (let i = 1; i < 4; i++) cmcs.push({cmc: i, cards: []});
        for (let cid of cids) {
            if (!cmcs.find((cmc) => cmc.cmc === cid.card.cmc)) {
                cmcs.push({cmc: cid.card.cmc, cards: []});
            }
        }

        let filledCmcs = cmcs.map(cmc => {
            for (let cid of cids) {
                if (cmc.cmc === cid.card.cmc) {
                    for (let i = 0; i < cid.amount; i++) {
                        cmc.cards.push(cid);
                    }
                }
            }
            return cmc;
        });

        let wrapperStyle = {
            overflowY: "auto",
            height: "500px",
            padding: "10px"
        };

        let rowStyle = {
            width: filledCmcs.length * 200 + "px"
        };

        return (
            <div className="row">
                <div className="col-12">
                    <div style={wrapperStyle}>
                        <div className="row" style={rowStyle}>
                            {orderBy(filledCmcs, "cmc").map(cmc => {
                                return (
                                    <div className="col-sm" key={cmc.cmc}>
                                        {orderBy(cmc.cards, "name").map((cid, index) => {
                                            return <DeckCard card={cid.card} key={cmc.cmc + "-" + index}
                                                             index={index} amount={cid.amount}
                                                             onCardClick={(leftClick) => this.addRemoveCard(cid.card, leftClick)}/>;
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

