import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import "bootstrap";
import CardImage from "components/CardImage";
import {imgFolder} from "config/config";
import $ from "jquery";
import React from "react";
import {Link, Redirect, Route} from "react-router-dom";
import "styles/Card.css";
import "styles/stateless.css";
import {getNewAmount} from "utils";

export const Checkable = ({checked, label, imgURL, onToggleCheck}) => {
    let style = {
        padding: '4px',
        height: '40px',
        width: '40px',
        display: 'inline-block',
        margin: '2px',
        borderRadius: '3px'
    };

    if (checked) {
        style.border = '1px solid #AAA';
        style.backgroundColor = 'rgba(67, 188, 221, 0.4)';
    }

    return (
        <div className={'pointer' + (checked ? ' active' : '')} title={label} style={style}
             onClick={() => onToggleCheck(!checked)}>
            <img src={imgURL} alt={label} className="w-100"/>
        </div>
    );
};

export const PercentageBar = ({amount, total, displayAsAmount = false, color}) => {
    let percentage = total ? parseInt(amount * 100 / total, 0) : 100;
    let outer = {height: '20px', backgroundColor: '#f3f3f3'};
    let inner = {width: percentage + '%'};
    if (color) inner.backgroundColor = '#' + color;
    return (
        <div className="progress w-100 m-1 border" style={outer}>
            <div className="progress-bar text-dark" style={inner}>
                {!displayAsAmount ? percentage + '%' : amount + " / " + total}
            </div>
        </div>
    );
};

export const PlusMinus = ({amount, onAmountChange}) => {
    return (
        <div className='input-group input-group-sm PlusMinusInputGroup'>
            <div className='input-group-prepend'>
            <span className='input-group-text p-1'>
                <FAI icon="minus" className="minus pointer"
                   onClick={() => onAmountChange(getNewAmount(amount, '-'))}/>
            </span>
            </div>
            <span className='form-control amount text-center'>{amount}</span>
            <div className='input-group-append'>
            <span className='input-group-text p-1'>
                <FAI icon="plus" className='plus pointer'
                   onClick={() => onAmountChange(getNewAmount(amount, '+'))}/>
            </span>
            </div>
        </div>
    );
};

export const PrivateRoute = ({...props}) => {
    if (!localStorage.getItem("authKey")) return <Redirect to={"/"}/>;
    return <Route {...props}/>;
};

export const ProgressBar = () => {
    return (
        <div className='progress'>
            <div className='progress-bar progress-bar-striped progress-bar-animated bg-info w-100 mx-auto'
                 role='progressbar' aria-valuenow='75' aria-valuemin='0' aria-valuemax='100'/>
        </div>
    );
};

export const ProgressCircle = ({color, size}) => {
    let style = {
        border: '16px solid #f3f3f3',
        borderTop: '16px solid ' + color,
        borderRadius: '50%',
        width: size + 'px',
        height: size + 'px',
        animation: 'spin 2s linear infinite',
    };
    return (
        <div className="ProgressCircle" style={style}/>
    );
};

export const RadioChoice = ({value, list, onToggleCheckable}) => {
    if (!list) return null;
    return (
        list.map(function (c) {
            return <Checkable key={c.value} imgURL={c.imgURL} label={c.label}
                              checked={value === c.value}
                              onToggleCheck={checked => onToggleCheckable(c.value, checked)}/>
        }, this)
    );
};

export const Range = ({symbol, value, label, onFocusLoss, onValueChange}) => {
    return (
        <div className='row'>
            <div className='col-12 col-sm-3'><label>{label}</label></div>
            {['<', '=', '>'].map(function (s) {
                return (
                    <div className='col-4 col-sm-3 py-0 px-1' key={s}>
                        <input type='text' className='form-control ml-1 w-100'
                               placeholder={s} value={symbol === s ? value : ''} key={s} onBlur={onFocusLoss}
                               onChange={(e) => onValueChange($(e.target).val(), s)}/>
                    </div>
                );
            }, this)}
        </div>
    );
};

export const SearchCard = ({card, history}) => {
    return (
        <div className='Card pointer' onClick={() => history.push('/app/cards/' + card.id)}>
            <CardImage name={card.name} set={card.lastPrint.set}
                       number={card.lastPrint.number}/>
            <div className={card.total >= 4 ? 'total playset' : 'total'}
                 title='Copies of that card amongst all sets and languages in your collection'>
                {card.total}
            </div>
        </div>
    );
};

export const SetIcon = ({set}) => {
    let linkStyle = {
        color: 'black',
        fontVariant: 'small-caps'
    };

    let color = 'timeshifted';
    if (set.type === 'expansion') color = 'rare';
    else if (set.type === 'core') color = 'common';
    let icon = 'ss ss-' + color + ' ss-' + set.code.toLowerCase();

    return (
        <div className='card border-0 bg-transparent'>
            <div className='card-body text-left text-sm-center'>
                <Link to={'/app/sets/' + set.code} style={linkStyle}>
                    <div className='d-none d-sm-inline'>
                        <h1><i className={icon}/></h1>
                        <h4 className='card-title mt-2'>{set.name}</h4>
                    </div>
                    <div className='d-sm-none'>
                        <h3><i className={icon}/> {set.name}</h3>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export const Tabs = ({entries, activeTab, onTabClick}) => {
    return (
        <ul className='nav nav-tabs w-100 mt-2 mb-4'>
            {entries.map((entry) => {
                let active = activeTab === entry.code ? ' active' : '';
                return (
                    <span id={'tab-' + entry.code} key={entry.code} onClick={() => onTabClick(entry.code)}
                          className={'nav-item nav-link pointer bg-transparent' + active}>
                        {entry.icon && <FAI icon={["fal", entry.icon]} className="mr-2"/>}
                        {entry.label}
                    </span>
                );
            })}
        </ul>
    );
};

export const TextWithSymbols = ({text}) => {
    if (!text) return '';

    // To uppercase
    let regex = /{([A-Za-z]|[0-9]|\/)*}/gm;
    text = text.replace(regex, v => v.toUpperCase());

    // Replace colors
    regex = /{([WUBRGSPTQE]|\d{1,2})}/gm;
    let subst = '<img class="symbol" style="height:20px" src="' + imgFolder + '/symbols/$1.png"/>';
    text = text.replace(regex, subst);

    // Replace numbers
    regex = /{([WUBRGP2])\/([WUBRGP])}/gm;
    subst = '<img class="symbol" style="height:20px" src="' + imgFolder + '/symbols/$1$2.png"/>';
    text = text.replace(regex, subst);

    let markup = {__html: text};
    return <span dangerouslySetInnerHTML={markup}/>;
};

export const Toggle = ({options, value, onToggle}) => {
    return (
        <div className="btn-group btn-group-toggle w-100">
            {options.map((o) => {
                let classes = o.value === value ? 'btn btn-secondary disabled' : 'btn btn-outline-secondary';
                return (
                    <label className={classes} key={o.value}
                           onClick={() => onToggle(o.value)}>
                        <input type="radio"/>
                        <span>{o.label}</span>
                    </label>
                );
            })}
        </div>
    );
};