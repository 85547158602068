import Chart from "chart.js";
import {PercentageBar, ProgressBar, ProgressCircle} from "components/stateless";
import {colors} from "config/theme";
import React from "react";
import {api} from "utils";
import MultiList from "./MultiList";

export default class CardStats extends React.Component {

    state = {
        statistics: null,
        colors: null,
        types: null,
        subtypes: null,
        formats: null
    };

    constructor(props) {
        super(props);
        this.listChange = this.listChange.bind(this);
    }

    componentDidMount() {
        api("GET", "/lists", {categories: ['colors', 'types', 'subtypes', 'formats']}, ({status, data}) => {
            let content = {
                colors: {
                    list: data.colors,
                    selected: []
                },
                types: {
                    list: data.types.sort((a, b) => a.label > b.label),
                    selected: []
                },
                subtypes: {
                    list: data.subtypes.sort((a, b) => a.label > b.label),
                    selected: []
                },
                formats: {
                    list: data.formats.sort((a, b) => a.label > b.label),
                    selected: []
                }
            };
            this.setState(content, this.fetchStatistics);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.amount !== prevProps.amount) this.fetchStatistics();
    }

    listChange(field, value) {
        field = this.state[field];
        field.selected = value;
        this.setState({[field.name]: field});
        this.fetchStatistics();
    }

    fetchStatistics() {
        this.setState({statistics: null});

        // Prepare form
        let form = {
            amount: this.props.amount,
            colors: this.state.colors.selected.map(e => e.code),
            types: this.state.types.selected.map(e => e.code),
            subtypes: this.state.subtypes.selected.map(e => e.code),
            formats: this.state.formats.selected.map(e => e.code)
        };

        // Send the request
        api("GET", "/statistics/cards", form, ({status, data}) => {
            this.setState({statistics: data}, this.renderPieChart);
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    {this.renderFilters()}
                    <div className="my-3">
                        <canvas id="DoughnutChart"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        {this.renderStatsByColor()}
                        {this.renderStatsByType()}
                    </div>
                </div>
            </div>
        );
    }

    renderStatsByColor() {
        if (this.state.colors && this.state.colors.selected.length) return null;
        return (
            <div>
                <h2 className="my-3">By color</h2>
                {!this.state.statistics ? this.Progress : (
                    <div>
                        {this.state.statistics.byColor.map(c => (
                            <div className="row" key={c.code}>
                                <div className="col-sm-4">{c.label}</div>
                                <div className="col-sm-8">
                                    <PercentageBar amount={c.amount} total={c.total} color={colors.color[c.code]}
                                                   displayAsAmount={this.props.display === 'amount'}/>
                                </div>
                            </div>
                        ))}
                    </div>)}
            </div>
        );
    }

    renderStatsByType() {
        if (this.state.types && this.state.types.selected.length) return null;
        return (
            <div>
                <h2 className="my-3">By type</h2>
                {!this.state.statistics ? this.Progress : (
                    <div>
                        {this.state.statistics.byType.map(t => {
                            return (
                                <div className="row" key={t.code}>
                                    <div className="col-sm-4">{t.label}</div>
                                    <div className="col-sm-8">
                                        <PercentageBar amount={t.amount} total={t.total}
                                                       color={colors.type[t.code.substring(0, 1)]}
                                                       displayAsAmount={this.props.display === 'amount'}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>)}
            </div>
        );
    }

    renderPieChart() {
        let ctx = document.getElementById("DoughnutChart");
        let amount = this.state.statistics.total.amount;
        let total = this.state.statistics.total.total;
        let amountName = this.props.amount === "1" ? 'copy' : 'playset';

        let data = {
            labels: [
                "Cards I have a " + amountName + " of",
                "Cards I don't have a " + amountName + " of",
            ],
            datasets: [{
                data: [amount, total - amount],
                backgroundColor: [
                    'rgba(253,216,53,0.2)',
                    'rgba(189,189,189,0.2)'
                ],
                borderColor: [
                    'rgba(253,216,53,1)',
                    'rgba(189,189,189,1)'
                ],
                borderWidth: 1
            }]
        };

        if (!this.doughnutChart) {
            let chart = new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: {
                    legend: {display: false}
                }
            });

            this.doughnutChart = chart;
        } else {
            this.doughnutChart.data = data;
            this.doughnutChart.update();
        }
    }

    renderFilters() {
        let filters = ['colors', 'types', 'subtypes', 'formats'];
        return (
            <div>
                <h2>Refine search to</h2>
                {filters.map(filter => (
                    <div className="my-2" key={filter}>
                        {!this.state[filter] ? <ProgressBar/> :
                            <MultiList list={this.state[filter].list}
                                       selected={this.state[filter].selected}
                                       placeholder={"all " + filter}
                                       onSelectionChange={data => this.listChange(filter, data)}/>}
                    </div>
                ))}
            </div>
        );
    }

    Progress = (
        <div className="my-2 d-flex justify-content-center">
            <ProgressCircle color="#00AAAA" size="100"/>
        </div>
    );
}