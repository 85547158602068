import SetCard from "components/SetCard";
import {ProgressBar, TextWithSymbols} from "components/stateless";
import AppLayout from "layouts/AppLayout";
import React from "react";
import {api, capitalize} from "utils";

export default class Card extends React.Component {

    state = {
        card: null,
        prints: []
    };

    constructor(props) {
        super(props);
        this.onCardChange = this.onCardChange.bind(this);
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        api("GET", '/cards/' +id, {}, ({status, data}) => {
            let prints = data.prints;
            delete data.prints;
            this.setState({
                card: data,
                prints: prints
            });
        });
    }

    onCardChange(cisId, language, amount) {
        let prints = this.state.prints.map(cis => {
            if(cis.id === cisId) {
                cis.collection = cis.collection.map(cic => {
                    if(cic.language === language) {
                        cic.amount = amount;
                    }
                    return cic;
                });
            }
            return cis;
        });

        this.setState({prints: prints});
    }

    render() {
        return (
            !this.state.card ? <ProgressBar/> : (
                <AppLayout history={this.props.history}>
                    <div className='row'>
                        <div className='col-lg-6 pb-3'>
                            {this.renderCardInfo()}
                        </div>
                        {this.state.prints.sort((a, b) => a.set.releaseDate < b.set.releaseDate ? 1 : -1).map(function (cis) {
                            return (
                                <div className='col-sm-4 col-lg-3 pb-4' key={cis.id}>
                                    <SetCard card={this.state.card} cardInSet={cis} setCode={cis.set.code}
                                             onChange={(language, amount) => this.onCardChange(cis.id, language, amount)}/>
                                </div>
                            );
                        }, this)}
                    </div>
                </AppLayout>
            )
        );
    }

    renderCardInfo() {
        return (
            <div className='card'>
                <div className='card-body'>
                    <h3 className="d-flex">
                        <span>{this.state.card.name}</span>
                        {!this.state.card.manaCost ? null :
                            <span className='ml-auto'>
                                <TextWithSymbols text={this.state.card.manaCost}/>
                            </span>}
                    </h3>
                </div>
                <ul className='list-group list-group-flush'>
                    <li className='list-group-item'>
                        <h4 className="d-flex">
                            <span>{this.state.card.type}</span>
                            {this.state.card.types.includes("Creature") &&
                            <span className="ml-auto">{this.state.card.power}/{this.state.card.toughness}</span>}
                        </h4>
                    </li>

                    {this.state.card.text &&
                    <li className='list-group-item'>
                        <TextWithSymbols text={this.state.card.text}/>
                    </li>}

                    <li className="list-group-item">
                        {this.state.card.legalities.map(l => {
                            let badgeColor = l.legality === 'legal' ? 'success' : l.legality === 'banned' ? 'danger' : 'warning';
                            return (
                                <h4 className="d-inline" key={l.format}>
                                    <span className={"badge badge-" + badgeColor + " mr-2"}
                                          title={capitalize(l.legality)}>
                                        {capitalize(l.format)}
                                    </span>
                                </h4>
                            );
                        })}
                    </li>
                </ul>
            </div>
        );
    }
}

