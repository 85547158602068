import CardImage from "components/CardImage";
import {Checkable, TextWithSymbols} from "components/stateless";
import {imgFolder} from "config/config";
import $ from "jquery";
import React from "react";
import {api, capitalize} from "utils";

export default class DeckCardSearch extends React.Component {

    state = {
        colors: [],
        inputs: {
            name: '',
            type: '',
            text: ''
        },
        searchResults: [],
        currentSearchCard: null,
        windowWidth: window.innerWidth
    };

    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.toggleColor = this.toggleColor.bind(this);
        this.search = this.search.bind(this);
        this.onCardClick = this.onCardClick.bind(this);
    }

    componentDidMount() {
        window.onresize = () => {
            this.setState({windowWidth: window.innerWidth});
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.format !== this.props.format) this.search();
    }

    onInputChange(e, name) {
        let inputs = this.state.inputs;
        inputs[name] = $(e.target).val();
        this.setState({inputs: inputs});
    }

    toggleColor(value, checked) {
        let colors = this.state.colors;
        let index = colors.indexOf(value);
        if (index > -1 && !checked) colors.splice(index, 1);
        else if (index <= -1 && checked) colors.push(value);
        this.setState({colors: colors});
        this.search();
    }

    search() {
        // Prepare form
        let form = [];
        let inputs = this.state.inputs;
        for (let input in inputs) {
            let value = inputs[input];
            if (value) form.push({name: input, value: value});
        }
        if (this.state.colors.length) form.push({name: 'colors', value: this.state.colors});
        if (!form.length) return null;
        form.push({name: 'formats', value: [this.props.format]});

        // Send form
        api("GET", '/cards', {
            form: form,
            orderBy: {
                value: 'name',
                order: 'asc'
            }
        }, ({status, data}) => {
            if (data && data.length > 0) this.setState({searchResults: data.slice(0, 20)});
            else this.setState({searchResults: []});
        });
    }

    onCardClick(card) {
        this.props.onAddCard(card);
    }

    render() {
        let searchCard = this.state.currentSearchCard;
        return (
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    {this.renderInputs()}
                    {this.renderColors()}
                </div>
                <div className="col-12 col-sm-7 col-md-4 col-lg-5">
                    {this.renderCardsList()}
                </div>
                <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                    {!searchCard ? <CardImage name="default"/> :
                        <CardImage name={searchCard.name}
                                   set={searchCard.lastPrint.set}
                                   number={searchCard.lastPrint.number}/>}
                </div>
            </div>
        );
    }

    renderInputs() {
        let inputs = ['name', 'type', 'text'];
        return (
            <div>
                {inputs.map(i => {
                    return (
                        <input type='text' className='form-control mb-1' placeholder={capitalize(i)}
                               value={this.state.inputs[i]} key={i}
                               onChange={e => this.onInputChange(e, i)}
                               onBlur={this.search}/>
                    )
                })}
            </div>
        );
    }

    renderColors() {
        let colors = ['W', 'U', 'B', 'R', 'G'];
        return (
            <div className='text-center'>
                {colors.map(function (v) {
                    return <Checkable key={v} checked={this.state.colors.indexOf(v) > -1}
                                      label={capitalize(v)}
                                      imgURL={imgFolder + '/icon/colors-' + v.toLowerCase() + '.png'}
                                      onToggleCheck={(checked) => this.toggleColor(v, checked)}/>;
                }, this)}
            </div>
        );
    }

    renderCardsList() {
        // TODO: color on hover
        let divStyle = {
            maxHeight: "270px",
            overflow: "auto"
        };
        return (
            <div style={divStyle} className="d-flex">
                <ul className='list-group align-self-stretch w-100'>
                    {this.state.searchResults.map((card) => {
                        let liStyle = {
                            backgroundColor: this.state.currentSearchCard === card ? 'rgba(50,50,50,0.2)' : 'inherit'
                        };
                        let badgeColor = card.total < 4 ? card.total < 1 ? 'danger' : 'warning' : 'success';
                        return (
                            <li className='list-group-item pointer py-2' key={card.id} style={liStyle}
                                onClick={() => this.onCardClick(card)}
                                onMouseEnter={() => this.setState({currentSearchCard: card})}>
                                <div className="d-flex">
                                    <span>{card.name}</span>
                                    <span className="ml-auto">
                                        <TextWithSymbols text={card.manaCost}/>
                                    </span>
                                    <h3 className="ml-3">
                                        <span className={"badge badge-" + badgeColor}>{card.total}</span>
                                    </h3>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

