import MultiList from "components/MultiList";
import {ProgressBar, Range, Tabs} from "components/stateless";
import React from "react";
import {api} from "utils";
import {ASCheckables} from "./AdvSearchComponents";

export default class AdvSearchForm extends React.Component {

    state = {
        activeTab: 'basic',
        form: [],
        lists: {
            colors: ['w', 'u', 'b', 'r', 'g'],
            rarities: ['common', 'uncommon', 'rare', 'mythic'],
            types: ['creature', 'sorcery', 'instant', 'enchantment', 'artifact', 'planeswalker', 'land'],
            sets: [],
            formats: [],
            subtypes: [],
            watermarks: []
        }
    };

    defaultForm = {
            name: '',
            type: '',
            text: '',
            colors: [],
            rarities: [],
            types: [],
            sets: [],
            formats: [],
            subtypes: [],
            watermarks: [],
            cmc: {
                value: '',
                symbol: ''
            },
            power: {
                value: '',
                symbol: ''
            },
            toughness: {
                value: '',
                symbol: ''
            },
            loyalty: {
                value: '',
                symbol: ''
            },
            copies: {
                value: '',
                symbol: ''
            },
            orderBy: {
                value: 'cmc',
                order: 'asc'
            }
        };

    constructor(props) {
        super(props);
        this.lastSearch = [];
        this.state.form = this.defaultForm;
        if(localStorage.getItem("advSearch")) {
            this.state.form = JSON.parse(localStorage.getItem("advSearch"));
            this.search();
        }

        this.orderChange = this.orderChange.bind(this);
        this.search = this.search.bind(this);
        this.emptyForm = this.emptyForm.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        api("GET", '/lists', {categories: ['sets', 'subtypes', 'watermarks', 'formats']}, ({status, data}) => {
            let lists = this.state.lists;
            let fields = ['sets', 'subtypes', 'watermarks', 'formats'];
            fields.map(code => {
                lists[code] = data[code].sort((a, b) => a.label > b.label);
                return null;
            });
            this.setState({lists: lists});
        });
    }

    orderChange(param, value) {
        let orderBy = this.state.form.orderBy;
        orderBy[param] = value;
        this.update('orderBy', orderBy);
    }

    emptyForm() {
        let def = JSON.parse(JSON.stringify(this.defaultForm));
        this.setState({form: def}, () => {
            localStorage.setItem('advSearch', JSON.stringify(def));
            this.props.onCardsChange([]);
        });
    }

    update(field, value, search = true) {
        let form = this.state.form;
        form[field] = value;
        this.setState({form: form}, () => {
            localStorage.setItem('advSearch', JSON.stringify(this.state.form));
            if (search) this.search();
        });
    }

    search() {
        // Checking if the form has changed
        let form = this.state.form;
        if (JSON.stringify(form) === JSON.stringify(this.lastSearch)) return null;
        this.lastSearch = JSON.parse(JSON.stringify(form));

        // Creating the params to send in the request
        let params = [];
        for (let code in form) {
            let value = form[code];
            if (typeof value.value !== 'undefined') {
                if (value.value && value.symbol) {
                    params.push({name: code, value: value.value, symbol: value.symbol});
                }
            } else {
                if (value && value.length > 0) {
                    if(typeof value[0].code !== 'undefined') {
                        value = value.map(e => e.code);
                    }
                    params.push({name: code, value: value});
                }
            }
        }

        if (!params.length) {
            this.props.onCardsChange([]);
            return null;
        }
        this.props.onCardsChange('Searching for cards...');

        // Sending the search request
        api("GET", '/cards', {form: params, orderBy: this.state.form.orderBy}, ({status, data}) => {
            if (data && data.length > 0) this.props.onCardsChange(data);
            else this.props.onCardsChange('No cards match this search.');
        });
    }

    render() {
        let entries = [
            {label: 'Basic search', code: 'basic'},
            {label: 'Advanced', code: 'advanced'},
        ];

        return (
            <div>
                <Tabs entries={entries} activeTab={this.state.activeTab}
                      onTabClick={code => this.setState({activeTab: code})}/>
                <form>
                    {this.state.activeTab === 'basic' && this.renderBasicTab()}
                    {this.state.activeTab === 'advanced' && this.renderAdvancedTab()}
                    <hr/>
                    {this.renderFormOptions()}
                </form>
            </div>
        );
    }

    renderBasicTab() {
        return (
            <div className='row'>
                <div className='col-12 col-md-6'>
                    {this.renderCheckables()}
                </div>
                <div className='col-12 col-md-6'>
                    {this.renderInputs()}
                </div>
            </div>
        );
    }

    renderAdvancedTab() {
        return (
            <div className='row'>
                <div className='col-12 col-md-6'>
                    {this.renderRanges()}
                </div>
                <div className='col-12 col-md-6'>
                    {this.renderMultiLists()}
                </div>
            </div>
        );
    }

    renderFormOptions() {
        return (
            <div className='row'>
                <div className='col-12 col-md-6'>
                    {this.renderOrderBy()}
                </div>
                <div className='col-12 col-md-6'>
                    <button className='btn btn-info w-100 my-1' type='button' onClick={this.emptyForm}>
                        Empty form
                    </button>
                </div>
            </div>
        );
    }

    renderCheckables() {
        let form = this.state.form;
        let lists = this.state.lists;
        return (
            <div className='row my-1'>
                <div className='col-12'>
                    <ASCheckables label="Color" selected={form.colors} code="color" list={lists.colors}
                                  onUpdate={selected => this.update("colors", selected)}/>
                    <ASCheckables label="Rarity" selected={form.rarities} code="rarity" list={lists.rarities}
                                  onUpdate={selected => this.update("rarities", selected)}/>
                    <ASCheckables label="Type" selected={form.types} code="type" list={lists.types}
                                  onUpdate={selected => this.update("types", selected)}/>
                </div>
            </div>
        );
    }

    renderInputs() {
        let inputs = ['Name', 'Type', 'Text'];
        return (
            <div className='row my-1'>
                <div className='col-12'>
                    {inputs.map((label) => {
                        let code = label.toLowerCase();
                        return (
                            <input type='text' className='form-control mb-2 w-100' autoComplete='off'
                                   placeholder={label} key={code} value={this.state.form[code]}
                                   onChange={e => this.update(code, e.target.value, false)}
                                   onBlur={this.search}/>
                        );
                    }, this)}
                </div>
            </div>
        );
    }

    renderMultiLists() {
        let fields = ['set', 'format', 'subtype', 'watermark'];
        return (
            <div className='row my-1'>
                <div className='col-12'>
                    {fields.map(name => {
                        let code = name + "s";
                        let label = "Filter by " + name;
                        if (!this.state.lists[code].length) return <div className="py-2" key={code}><ProgressBar/>
                        </div>;
                        return (
                            <div key={code} className='mb-1'>
                                <MultiList list={this.state.lists[code]} selected={this.state.form[code]}
                                           placeholder={label}
                                           onSelectionChange={selected => this.update(code, selected)}/>
                            </div>
                        );
                    }, this)}
                </div>
            </div>
        );
    }

    renderRanges() {
        let ranges = [
            {code: 'cmc', label: 'CMC'},
            {code: 'power', label: 'Power'},
            {code: 'toughness', label: 'Toughness'},
            {code: 'loyalty', label: 'Loyalty'},
            {code: 'copies', label: 'In my collection'}
        ];

        let form = this.state.form;

        return (
            <div className='row my-1'>
                <div className='col-12'>
                    {ranges.map(range => {
                        let code = range.code;
                        return (
                            <div className='mb-2' key={code}>
                                <Range label={range.label} value={form[code].value} symbol={form[code].symbol}
                                       onValueChange={(value, symbol) => this.update(code, {
                                           value: value,
                                           symbol: symbol
                                       }, false)}
                                       onFocusLoss={this.search}/>
                            </div>
                        );
                    }, this)}
                </div>
            </div>
        );
    }

    renderOrderBy() {
        return (
            <div className='row my-1'>
                <div className='col-sm-8 text-center'>
                    <select className='form-control' value={this.state.form.orderBy.value}
                            onChange={e => this.orderChange('value', e.target.value)}>
                        <option value='name'>Order by name</option>
                        <option value='cmc'>Order by CMC</option>
                        <option value='power'>Order by power</option>
                        <option value='toughness'>Order by toughness</option>
                        <option value='loyalty'>Order by loyalty</option>
                    </select>
                </div>
                <div className='col-sm-4 text-center'>
                    <select className='form-control' value={this.state.form.orderBy.order}
                            onChange={e => this.orderChange('order', e.target.value)}>
                        <option value='asc'>Ascending</option>
                        <option value='desc'>Descending</option>
                    </select>
                </div>
            </div>
        );
    }
}
