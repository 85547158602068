import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import {ProgressBar, SetIcon} from "components/stateless";
import $ from "jquery";
import AppLayout from "layouts/AppLayout";
import React from "react";
import {Link} from "react-router-dom";
import {api} from "utils";

export default class Collection extends React.Component {

    state = {
        sets: null,
        ftSearch: '',
        filters: {
            Core: true,
            Expansion: true,
            Other: false
        }
    };

    constructor(props) {
        super(props);
        this.toggleFilter.bind(this.toggleFilter);
        this.ftSearch.bind(this.ftSearch);
        this.filter.bind(this.filter);
    }

    componentDidMount() {
        api("GET", "/sets", {}, ({status, data}) => {
            let sets = data.sort((a, b) => a.releaseDate < b.releaseDate);
            this.setState({sets: sets});
        });
    }

    toggleFilter(filter) {
        let filters = this.state.filters;
        filters[filter] = !filters[filter];
        this.setState({filters: filters});
    }

    ftSearch(e) {
        let value = $(e.target).val();
        this.setState({ftSearch: value});
    }

    filter(set) {
        let show = false;
        let filters = this.state.filters;
        if (filters.Core && set.type === 'core') show = true;
        if (filters.Expansion && set.type === 'expansion') show = true;
        if (filters.Other && set.type !== 'core' & set.type !== 'expansion') show = true;
        if (this.state.ftSearch && !set.name.toLowerCase().includes(this.state.ftSearch.toLowerCase())) show = false;
        return show;
    }

    render() {
        return (
            <AppLayout history={this.props.history}>
                {this.renderFilters()}
                <hr/>
                {this.state.sets ? this.renderSets() : <ProgressBar/>}
            </AppLayout>
        );
    }

    renderFilters() {
        let filters = ['Core', 'Expansion', 'Other'];
        return (
            <div className='d-flex flex-column flex-md-row justify-content-md-around align-items-md-center'>
                {filters.map(function (f) {
                    return (
                        <div className='form-check mb-1 mr-2' key={f}>
                            <input className='form-check-input' type='checkbox'
                                   checked={this.state.filters[f]}
                                   onChange={() => this.toggleFilter(f)}/>
                            <label className='form-check-label'>
                                {f} sets
                            </label>
                        </div>
                    );
                }, this)}
                <div className="mb-1 mr-2">
                    <input type='search' className='form-control' placeholder='Search by name'
                           onKeyUp={(event) => this.ftSearch(event)}/>
                </div>
                <div className="mb-1 ml-auto">
                    <Link to="/app/collection/import-export" className="btn btn-secondary">
                        <FAI icon="download" className="mr-2"/>
                        Import / export
                    </Link>
                </div>
            </div>
        );
    }

    renderSets() {
        return (
            <div className='row'>
                {this.state.sets.map(function (s) {
                    if (this.filter(s)) {
                        return (
                            <div className='col-sm-4 col-md-3 col-lg-2' key={s.id}>
                                <SetIcon set={s}/>
                            </div>
                        );
                    }
                    return null;
                }, this)}
            </div>
        );
    }
}
