import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import fileDownload from "js-file-download";
import AppLayout from "layouts/AppLayout";
import moment from 'moment';
import React from "react";
import {api} from "utils";

export default class ImportExport extends React.Component {

    state = {
        exportStatus: null,
        importStatus: null,
        fileName: "Choose a file...",
        messages: {
            running: {text: " in progress...", color: "info"},
            error: {text: " failed.", color: "danger"},
            success: {text: " successful!", color: "success"}
        }
    };

    constructor(props) {
        super(props);
        this.export = this.export.bind(this);
        this.import = this.import.bind(this);
    }

    export() {
        this.setState({exportStatus: "running"});
        api("GET", "/collection/export", [], ({status, data}) => {
            if (status === 400) this.setState({exportStatus: "error"});
            else {
                this.setState({exportStatus: "success"});
                let filename = moment().format("YYYY-MM-DD_HH-mm-ss") + "_export.json";
                fileDownload(JSON.stringify(data), filename);
            }
        });
    }

    import() {
        let file = document.getElementById("ImportCollection").files[0];
        let reader = new FileReader();
        reader.onload = e => {
            this.setState({importStatus: "running"});
            let data = JSON.parse(e.target.result);
            api("POST", "/collection/import", {import: data}, ({status, data}) => {
                if (status === 400) this.setState({importStatus: "error"});
                else this.setState({importStatus: "success"});
            });
        };
        reader.readAsText(file);
    }

    render() {
        return (
            <AppLayout history={this.props.history}>
                <div className="row">
                    {this.renderExport()}
                    {this.renderImport()}
                </div>
            </AppLayout>
        );
    }

    renderExport() {
        let status = this.state.exportStatus;
        let message = status ? this.state.messages[status] : null;
        return (
            <div className="col-6 text-center">
                <h1 className="mb-4">Export</h1>
                <button className="btn btn-info ml-auto mb-1"
                        onClick={this.export}>
                    <FAI icon="file-export" className="mr-2"/>
                    Export collection in JSON format
                </button>
                {message && <div className={"mt-2 alert alert-" + message.color}>
                    {"Export" + message.text}
                </div>}
            </div>
        );
    }

    renderImport() {
        let status = this.state.importStatus;
        let message = status ? this.state.messages[status] : null;
        return (
            <div className="col-6 text-center">
                <h1 className="mb-4">Import</h1>
                <div className="custom-file">
                    <input type="file" className="custom-file-input" id="ImportCollection"
                           onChange={e => this.setState({fileName: e.target.files[0].name})}/>
                    <label className="custom-file-label" htmlFor="ImportCollection">
                        {this.state.fileName}
                    </label>
                </div>
                <button className="btn btn-info mt-2"
                        onClick={this.import}>
                    <FAI icon="file-import" className="mr-2"/>
                    Import collection from JSON file
                </button>
                {message && <div className={"mt-2 alert alert-" + message.color}>
                    {"Import" + message.text}
                </div>}
            </div>
        );
    }
}

