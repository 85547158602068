import App from "App";
import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "registerServiceWorker";
import "styles/index.css";

$.ajaxSetup({
    crossOrigin: true,
    accept: 'application/json',
    beforeSend: function (request) {
        request.setRequestHeader('Authorization', 'Bearer ' + localStorage.authKey);
    },
    error: function (jqXHR) {
        if (jqXHR.status === 403) {
            localStorage.clear();
        } /*else {
            alert('An error occured (' + jqXHR.status + ' ' + jqXHR.statusText + ')');
        }*/
    }
});

ReactDOM.render(<App/>, document.getElementById('root'));
registerServiceWorker();
