import {PrivateRoute} from "components/stateless";
import "config/fontawesome-light.config.js";
import "config/fontawesome-solid.config";
import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AdvancedSearch from "routes/AdvancedSearch";
import Buylists from "routes/Buylists";
import Card from "routes/Card";
import Collection from "routes/Collection";
import Deck from "routes/Deck";
import Decks from "routes/Decks";
import Import from "routes/Import";
import ImportExport from "routes/ImportExport";
import Login from "routes/Login";
import Logout from "routes/Logout";
import Set from "routes/Set";
import SignUp from "routes/SignUp";
import SimpleSearch from "routes/SimpleSearch";
import Statistics from "routes/Statistics";
import "styles/App.css";

export default class App extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path='/' component={Login}/>
                    <Route exact path='/signup' component={SignUp}/>
                    <Route exact path='/logout' component={Logout}/>
                    <PrivateRoute exact path='/app'>
                        <Redirect to="/app/collection"/>
                    </PrivateRoute>
                    <PrivateRoute exact path='/app/simple-search/:search' component={SimpleSearch}/>
                    <PrivateRoute exact path='/app/advanced-search' component={AdvancedSearch}/>
                    <PrivateRoute exact path='/app/cards/:id' component={Card}/>
                    <PrivateRoute exact path='/app/collection' component={Collection}/>
                    <PrivateRoute exact path='/app/collection/import' component={Import}/>
                    <PrivateRoute exact path='/app/collection/import-export' component={ImportExport}/>
                    <PrivateRoute exact path='/app/sets/:code' component={Set}/>
                    <PrivateRoute exact path='/app/statistics' component={Statistics}/>
                    <PrivateRoute exact path='/app/decks/:id' component={Deck}/>
                    <PrivateRoute exact path='/app/decks' component={Decks}/>
                    <PrivateRoute exact path='/app/buylists' component={Buylists}/>
                    <PrivateRoute exact path='/app/buylists/:id' component={Buylists}/>
                </Switch>
            </Router>
        );
    }
}
