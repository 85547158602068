import Chart from "chart.js";
import {PercentageBar, ProgressCircle, Toggle} from "components/stateless";
import {colors} from "config/theme";
import React from "react";
import {api, brightenColor} from "utils";

export default class SetStatistics extends React.Component {

    state = {
        statistics: null,
        doughnutChart: null,
        display: 'amount',
        amount: '1'
    };

    componentDidMount() {
        this.fetchStatistics();
    }

    fetchStatistics() {
        api("GET", '/statistics/sets', {amount: this.state.amount, sets: [this.props.set]}, ({status, data}) => {
            this.setState({statistics: data}, this.renderPieChart);
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 mb-4">
                    {this.renderDisplayOptions()}
                </div>
                <div className="col-md-6">
                    <h2 className="my-3 text-center">In total</h2>
                    <canvas id="DoughnutChart"/>
                </div>
                <div className="col-md-6">
                    {this.renderStatsByRarity()}
                </div>
            </div>
        );
    }

    renderDisplayOptions() {
        let formData = {
            display: [
                {label: 'percentage', value: 'percentage'},
                {label: 'amount', value: 'amount'}
            ],
            amount: [
                {label: 'copy', value: '1'},
                {label: 'playset', value: '4'}
            ]
        };

        return (
            <div className="d-flex justify-content-start align-items-center">
                <div className="mr-3">
                    <Toggle options={formData.display} value={this.state.display}
                            onToggle={(value) => this.setState({'display': value})}/>
                </div>
                <span className="mr-3">of cards I have a</span>
                <div className="mr-3">
                    <Toggle options={formData.amount} value={this.state.amount}
                            onToggle={(value) => this.setState({'amount': value}, this.fetchStatistics)}/>
                </div>
                <span>of</span>
            </div>
        );
    }

    renderStatsByRarity() {
        return (
            <div>
                <h2 className="my-3 text-center">By rarity</h2>
                {!this.state.statistics ? (
                    <div className="my-2 d-flex justify-content-center">
                        <ProgressCircle color="#00AAAA" size="100"/>
                    </div>
                ) : (
                    <div>
                        {this.state.statistics.byRarity.map(r => {
                            let color = brightenColor(colors.rarity[r.code.substring(0, 1)], 100);
                            return (
                                <div className="row" key={r.code}>
                                    <div className="col-sm-4">{r.label}</div>
                                    <div className="col-sm-8">
                                        <PercentageBar amount={r.amount} total={r.total}
                                                       color={color}
                                                       displayAsAmount={this.state.display === 'amount'}/>
                                    </div>
                                </div>
                            );
                        }, this)}
                    </div>)}
            </div>
        );
    }

    renderPieChart() {
        let ctx = document.getElementById("DoughnutChart");
        let amount = this.state.statistics.total.amount;
        let total = this.state.statistics.total.total;
        let amountName = this.state.amount === "1" ? 'copy' : 'playset';

        let data = {
            labels: [
                "Prints I have a " + amountName + " of",
                "Prints I don't have a " + amountName + " of",
            ],
            datasets: [{
                data: [amount, total - amount],
                backgroundColor: [
                    'rgba(253,216,53,0.2)',
                    'rgba(189,189,189,0.2)'
                ],
                borderColor: [
                    'rgba(253,216,53,1)',
                    'rgba(189,189,189,1)'
                ],
                borderWidth: 1
            }]
        };

        if (!this.doughnutChart) {
            let chart = new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: {
                    legend: {display: false}
                }
            });

            this.doughnutChart = chart;
        } else {
            this.doughnutChart.data = data;
            this.doughnutChart.update();
        }
    }
}