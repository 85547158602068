import CollectionTable from "components/CollectionTable";
import PropTypes from "prop-types";
import React from "react";
import "styles/Card.css";
import CardImage from "./CardImage";

export default class SetCard extends React.Component {

    render() {
        return (
            <div className='Card'>
                <CardImage name={this.props.card.name} set={this.props.setCode} number={this.props.cardInSet.number}/>
                {this.renderBanner()}
                {this.renderNumber()}
                {this.renderTotal()}
            </div>
        );
    }

    renderBanner() {
        return (
            <div className='banner d-flex w-100 justify-content-center align-items-center'>
                <table className='w-75'>
                    <tbody>
                    {this.props.cardInSet.collection.map(cic =>
                        <CollectionTable
                            key={this.props.cardInSet.number + cic.language}
                            cisId={this.props.cardInSet.id}
                            set={this.props.setCode} number={this.props.cardInSet.number}
                            language={cic.language} amount={cic.amount}
                            onChange={amount => this.props.onChange(cic.language, amount)}/>
                    )}
                    </tbody>
                </table>
            </div>
        );
    }

    renderNumber() {
        let style = {
            position: 'absolute',
            bottom: '0',
            left: '0',
            padding: '5px',
            borderRadius: '0 8px 0 8px',
            backgroundColor: 'rgba(97, 218, 251, 1)',
            border: '1px solid #5a6572'
        };
        return (
            <div style={style} title="Collector's number">
                N° {this.props.cardInSet.number}
            </div>
        );
    }

    renderTotal() {
        return (
            <div className={this.props.card.total >= 4 ? 'total playset' : 'total'}
                 title='Copies of that card amongst all sets and languages in your collection'>
                {this.props.card.total}
            </div>
        );
    }
}

SetCard.propTypes = {
    cardInSet: PropTypes.object,
    card: PropTypes.object,
    setCode: PropTypes.string,
    onChange: PropTypes.func
};