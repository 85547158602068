import {imgFolder} from "config/config";
import React from "react";


export default class CardImage extends React.Component {

    state = {
        imgHasLoaded: false
    };

    style = {
        width: '100%',
        borderRadius: '4%',
        boxShadow: "0 4px 10px 0 #777777"
    };

    render() {
        return (
            <div>
                {/* Default image - Disappears when the card image has finished loading */}
                {this.renderDefaultImage()}
                {/* Card image */}
                {this.renderCardImage()}
            </div>
        );
    }

    renderDefaultImage() {
        if (!this.state.imgHasLoaded) {
            return <img src={imgFolder + '/back.jpg'} alt='Loading...' style={this.style}/>
        }
        return null;
    }

    renderCardImage() {
        if (this.props.name === 'default') return null;

        let set = this.props.set.toLowerCase();
        let number = this.props.number;
        let imageUrl = imgFolder + '/cards/' + set + '/' + number + '.jpg';

        return (
            <img src={imageUrl} alt={this.props.name} style={this.style}
                 className={this.state.imgHasLoaded ? '' : 'd-none'}
                 onLoad={() => this.setState({imgHasLoaded: true})}/>
        );
    }
}