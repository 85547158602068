import {Checkable} from "components/stateless";
import {imgFolder} from "config/config";
import React from "react";
import {capitalize} from "utils";

export class ASCheckables extends React.Component {

    constructor(props) {
        super(props);
        this.toggleCheckable = this.toggleCheckable.bind(this);
    }

    toggleCheckable(value, checked) {
        let sel = this.props.selected;
        let index = sel.indexOf(value);
        if (index > -1 && !checked) sel.splice(index, 1);
        else if (index <= -1 && checked) sel.push(value);
        this.props.onUpdate(sel);
    }

    render() {
        return (
            <div className='row'>
                <div className='col-12 col-sm-4 d-flex'>
                    <h3 className='align-self-center m-auto m-sm-0 ml-sm-auto'>{this.props.label}</h3>
                </div>
                <div className='col-12 col-sm-8 text-center text-sm-left'>
                    {this.props.list.map(function (t) {
                        return <Checkable key={t} checked={this.props.selected.indexOf(t) > -1}
                                          label={capitalize(t)}
                                          imgURL={imgFolder + '/icon/' + this.props.code + '-' + t + '.png'}
                                          onToggleCheck={checked => this.toggleCheckable(t, checked)}/>;
                    }, this)}
                </div>
            </div>
        );
    }
};