import {imgFolder} from "config/config";
import AppLayout from "layouts/AppLayout";
import React from "react";
import {Link} from "react-router-dom";
import {api} from "utils";

export default class SignUp extends React.Component {

    state = {
        message: null
    };

    constructor(props) {
        super(props);
        this.sendRequest = this.sendRequest.bind(this);
    }

    sendRequest() {
        let username = document.getElementById('username').value;
        let email = document.getElementById('email').value;
        let password1 = document.getElementById('password1').value;
        let password2 = document.getElementById('password2').value;

        let message = null;
        if (!username || username.length < 5) message = "Username is not long enough.";
        if (username.includes(' ')) message = "Username can't contain spaces.";
        if (!email) message = "Email is missing.";
        if (!email.includes('@') || !email.includes('.')) message = "Email is invalid.";
        if (!password1 || password1.length < 5) message = "Password is not long enough.";
        if (password1.includes(' ')) message = "Password can't contain spaces.";
        if (password1 !== password2) message = "Passwords don't match.";
        if (message) this.setState({message: {code: message, color: 'danger'}});
        else this.setState({message: null});

        if (!message) {
            this.setState({message: {code: "Creating your account...", color: 'info'}});
            api("POST", '/signup', {email: email, username: username, password: password1}, ({status, data}) => {
                if(status === 200) {
                    this.setState({message: {code: "Your account was successfully created!", color: 'success'}});
                } else {
                    this.setState({message: {code: "Signup failed.", color: 'danger'}});
                }
            });
        }
    }

    render() {
        let classes = "form-control my-2 text-center";
        return (
            <AppLayout history={this.props.history}>
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <img src={imgFolder + "/logo.png"} className="img-fluid mb-2" alt="MtG Manager"/>
                        <h1 className="text-center">Sign up!</h1>
                        <p className="text-danger my-2">
                            Sorry, you can't create an account yet, this is a private app for now.
                        </p>
                        <input className={classes} type="text" placeholder="Username" id="username"/>
                        <input className={classes} type="text" placeholder="Email" id="email"/>
                        <input className={classes} type="password" placeholder="Password" id="password1"/>
                        <input className={classes} type="password" placeholder="Repeat your password" id="password2"/>
                        <button className="btn btn-info my-2 w-100" onClick={this.sendRequest}>
                            Sign up
                        </button>
                        <p className="text-muted my-2">
                            Already have an account? <Link to="/">Go to login.</Link>
                        </p>
                        {this.state.message && (
                            <div className={"alert alert-" + this.state.message.color}>
                                {this.state.message.code}
                            </div>
                        )}
                    </div>
                </div>
            </AppLayout>
        );
    }
}

