import Buylist from "components/Buylist";
import ClickableTable from "components/ClickableTable";
import {ProgressBar} from "components/stateless";
import $ from "jquery";
import AppLayout from "layouts/AppLayout";
import React from "react";
import {api} from "utils";

export default class Buylists extends React.Component {

    state = {
        buylists: null
    };

    constructor(props) {
        super(props);
        this.getBuylists = this.getBuylists.bind(this);
        this.createNewBuylist = this.createNewBuylist.bind(this);
        this.deleteBuylist = this.deleteBuylist.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    componentDidMount() {
        this.getBuylists();
    }

    getBuylists() {
        api("GET", "/buylists", [], ({status, data}) => {
            if(status === 200) {
                if (data.length) this.setState({buylists: data});
                else this.setState({buylists: 'No buylists yet. Create your first one!'});
                this.props.history.push('/app/buylists/' + (data[0] ? data[0].id : ''));
            }
        });
    }

    createNewBuylist() {
        let name = $('#NewBuylistName').val();
        if (name) {
            api("POST", "/buylists", {name: name}, ({status, data}) => {
                this.getBuylists();
                $('#NewBuylistName').val('');
            });
        }
    }

    deleteBuylist(id) {
        let buylists = this.state.buylists;
        buylists = buylists.filter(b => b.id !== id);
        this.setState({buylists: buylists});
        this.props.history.push('/app/buylists/' + (buylists[0] && buylists[0].id));
    }

    changeName(id, name) {
        let buylists = this.state.buylists;
        buylists = buylists.map(b => {
            if (b.id === id) b.name = name;
            return b;
        });
        this.setState({buylists: buylists});
    }

    render() {
        let id = parseInt(this.props.match.params.id, 0);
        return (
            <AppLayout history={this.props.history}>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='mb-3'>{this.renderForm()}</div>
                        <div className='mb-3'>{this.renderList()}</div>
                    </div>
                    <div className="col-md-8">
                        {!id ? '' :
                            <Buylist id={id}
                                     onDelete={() => this.deleteBuylist(id)}
                                     onNameChange={(name) => this.changeName(id, name)}/>}
                    </div>
                </div>
            </AppLayout>
        );
    }

    renderForm() {
        return (
            <div className="d-flex flex-column">
                <div className="p-1">
                    <input type='text' className='form-control text-center' id='NewBuylistName'
                           placeholder="New buylist's name"/>
                </div>
                <div className="p-1">
                    <button type='button' className='btn btn-info w-100' onClick={this.createNewBuylist}>
                        Create a new buylist
                    </button>
                </div>
            </div>
        );
    }

    renderList() {
        if (!Array.isArray(this.state.buylists)) return <h3>{this.state.buylists}</h3>;
        if (!this.state.buylists.length) return <div className="p-1"><ProgressBar/></div>;

        let headers = [
            {label: 'Name', name: 'name', type: 'text', sortable: true},
            {label: 'Last updated', name: 'lastUpdate', type: 'timeSince', sortable: true}
        ];

        return <ClickableTable data={this.state.buylists} headers={headers} sortBy='lastUpdate'
                               ascending={false} baseURL='/app/buylists/' delete={false}/>;
    }
}

