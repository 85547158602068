import Nav from "components/Nav";
import React from "react";

export default class AppLayout extends React.Component {
    render() {
        return (
            <div className="App">
                <Nav history={this.props.history}/>
                <section>
                    <div className='container'>
                        {this.props.children}
                    </div>
                </section>
            </div>
        );
    }
}

