import AppLayout from "layouts/AppLayout";
import React from "react";
import {api} from "utils";

export default class Import extends React.Component {

    state = {
        step: 1,
        sets: [],
        currentSet: null,
        export: null,
        import: null,
        message: {
            code: 'step1',
            parameter: null
        }
    };

    constructor(props) {
        super(props);
        this.pasteImport = this.pasteImport.bind(this);
        this.toLines = this.toLines.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.sendImport = this.sendImport.bind(this);
        document.onpaste = this.pasteImport;
    }

    componentDidMount() {
        api("GET", '/lists', {categories: ['sets']}, ({status, data}) => {
            let sets = data.sets.sort((a, b) => (a.label > b.label) ? 1 : -1);
            this.setState({sets: sets});
        });
    }

    fetchExport() {
        if (!this.state.currentSet) return null;
        let url = '/sets/' + this.state.currentSet + '/export';
        api("GET", url, {}, ({status, data}) => {
            this.setState({
                export: data.prints,
                step: 2,
                message: {code: 'step2', parameter: null}
            });
        });
    }

    pasteImport(e) {
        if (this.state.step !== 2) return null;
        this.setState({
            import: e.clipboardData.getData('Text'),
            step: 3,
            message: {code: 'step3', parameter: null}
        }, this.checkImport);
    }

    onTextChange(e) {
        this.setState({import: e.target.value}, this.checkImport);
    }

    checkImport() {
        let code = 'importCorrect';
        let parameter = null;

        try {
            let exp = this.state.export;
            let imp = JSON.parse(this.state.import);

            // Check for missing numbers and languages
            if (code === 'importCorrect') {
                for (let number in exp) {
                    if (typeof imp[number] === 'undefined') {
                        code = 'numberMissing';
                        parameter = {number: number};
                        break;
                    } else {
                        for (let lang in exp[number]) {
                            if (typeof imp[number][lang] === 'undefined') {
                                code = 'langMissing';
                                parameter = {number: number, lang: lang};
                                break;
                            }
                        }
                    }
                }
            }

            // Check for invalid numbers
            if (code === 'importCorrect') {
                for (let number in imp) {
                    for (let lang in imp[number]) {
                        let amount = imp[number][lang];
                        if (isNaN(amount) || amount < 0) {
                            code = 'amountIncorrect';
                            parameter = {number: number, lang: lang};
                            break;
                        }
                    }
                }
            }

            // Check for useless numbers and languages
            if (code === 'importCorrect') {
                for (let number in imp) {
                    if (typeof exp[number] === 'undefined') {
                        code = 'numberUseless';
                        parameter = {number: number};
                        break;
                    } else {
                        for (let lang in imp[number]) {
                            if (typeof exp[number][lang] === 'undefined') {
                                code = 'langUseless';
                                parameter = {number: number, lang: lang};
                                break;
                            }
                        }
                    }
                }
            }
        } catch (e) {
            code = 'jsonInvalid';
        }

        this.setState({message: {code: code, parameter: parameter}});
    }

    toLines(string) {
        let lineBreak = '\n';
        string = string.replace(/},/g, '},' + lineBreak);
        string =
            string.slice(0, 1) + lineBreak +
            string.slice(1, string.length - 1) + lineBreak +
            string.slice(string.length - 1);
        string = string.replace(/\n\n/g, lineBreak);
        return string;
    }

    sendImport() {
        this.setState({message: {code: 'importInProgress'}});
        let url = '/sets/' + this.state.currentSet + '/import';
        api("POST", url, {import: JSON.parse(this.state.import)}, ({status, data}) => {
            if(status === 200) {
                this.setState({
                    message: {code: 'importSuccessful'},
                    step: 1,
                    currentSet: null,
                    import: null,
                    export: null
                });
            } else {
                this.setState({message: {code: 'importFailed'}});
            }
        });
    }

    render() {
        return (
            <AppLayout history={this.props.history}>
                <div className="row">
                    <div className="col-12">
                        {this.renderMessage()}
                    </div>
                    <div className="col-12">
                        {this.renderSelect()}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        {this.renderExport()}
                    </div>
                    <div className="col-6">
                        {this.renderImport()}
                    </div>
                </div>
            </AppLayout>
        );
    }

    renderMessage() {
        let message = this.state.message;
        let content = null;
        let color = 'info';

        switch (message.code) {
            // Messages without parameters
            case 'step1':
                content = <span><b>Step 1</b> - Choose a set to import cards into</span>;
                break;
            case 'step2':
                content = <span><b>Step 2</b> - Paste your import with <code>Ctrl + V</code></span>;
                break;
            case 'step3':
                content = <span><b>Step 3</b> - Check that your import matches the example</span>;
                break;
            case 'jsonInvalid':
                content = <span>This is not valid JSON</span>;
                color = 'danger';
                break;
            case 'importInProgress':
                content = <span>Import in progress...</span>;
                break;
            case 'importSuccessful':
                content = <span>The import was successful!</span>;
                color = 'success';
                break;
            case 'importFailed':
                content = <span>Sorry, the import failed.</span>;
                color = 'danger';
                break;
            // Messages with parameters
            default:
                let p = message.parameter;
                switch (message.code) {
                    case 'numberMissing':
                        content = <span>Number {p.number} is missing.</span>;
                        color = 'danger';
                        break;
                    case 'numberUseless':
                        content = <span>Number {p.number} is useless.</span>;
                        color = 'warning';
                        break;
                    case 'langMissing':
                        content = <span>Language {p.lang.toUpperCase()} for number {p.number} is missing.</span>;
                        color = 'danger';
                        break;
                    case 'langUseless':
                        content = <span>Language {p.lang.toUpperCase()} for number {p.number} is useless.</span>;
                        color = 'warning';
                        break;
                    case 'amountIncorrect':
                        content = <span>Amount is invalid for number {p.number} in {p.lang.toUpperCase()}</span>;
                        color = 'danger';
                        break;
                    default:
                        break;
                }
                break;
        }

        return (
            <div>
                {['importCorrect', 'numberUseless', 'langUseless'].includes(message.code) && (
                    <div className="mb-2">
                        <button className="btn btn-info btn-lg w-100" type="button"
                                onClick={this.sendImport}>
                            Your import is correct! Click here to send.
                        </button>
                    </div>
                )}
                {message.code !== 'importCorrect' && (
                    <div className={"alert alert-" + color}>
                        {content}
                    </div>
                )}
            </div>
        );
    }

    renderSelect() {
        return (
            <select className="form-control" disabled={this.state.step !== 1}
                    onChange={e => this.setState({currentSet: e.target.value}, this.fetchExport)}>
                <option value="">-- Choose a set --</option>
                {this.state.sets.length && this.state.sets.map(s => {
                    return <option key={s.code} value={s.code}>{s.label + " - " + s.code.toUpperCase()}</option>
                })}
            </select>
        );
    }

    renderExport() {
        if (!this.state.export) return null;
        let style = {
            display: 'block',
            height: '400px',
            backgroundColor: 'rgba(0,0,0,0.1)',
            color: 'rgba(0,0,0,0.5)',
            fontFamily: '"Consolas", monospace'
        };

        return (
            <textarea className="rounded border border-secondary w-100 p-2" style={style} readOnly={true}
                      value={this.toLines(JSON.stringify(this.state.export))}>
            </textarea>
        );
    }

    renderImport() {
        if (!this.state.import) return null;

        let style = {
            display: 'block',
            height: '400px',
            fontFamily: '"Consolas", monospace'
        };

        return (
            <textarea className="rounded border border-secondary w-100 p-2" style={style}
                      onChange={this.onTextChange} value={this.toLines(this.state.import)}>
            </textarea>
        );
    }
}