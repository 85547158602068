import {SearchCard} from "components/stateless";
import AppLayout from "layouts/AppLayout";
import React from "react";
import {Redirect} from "react-router-dom";
import {api} from "utils";

export default class SimpleSearch extends React.Component {

    state = {
        cards: []
    };

    componentDidMount() {
        if (this.props.match.params.search) this.search();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.search !== prevProps.match.params.search) this.search();
    }

    search() {
        let form = {
            form: [
                {
                    name: 'name',
                    value: this.props.match.params.search
                }
            ],
            orderBy: {
                value: 'name',
                order: 'asc'
            }
        };

        api("GET", '/cards', form, ({status, data}) => {
            if (data && data.length > 0) this.setState({cards: data});
            else this.setState({cards: []});
        });
    }

    render() {
        let cards = this.state.cards;
        if (cards.length === 1) {
            let url = '/app/cards/' + cards[0].id;
            return <Redirect to={url}/>;
        }
        return (
            <AppLayout history={this.props.history}>
                <div className='row'>
                    {cards.map(function (c) {
                        return (
                            <div className='col-6 col-md-4 col-lg-3 mb-3' key={c.id}>
                                <SearchCard card={c} history={this.props.history}/>
                            </div>
                        );
                    }, this)}
                </div>
            </AppLayout>
        );
    }
}

