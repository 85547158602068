import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import {api} from "utils";

export default class BuylistImport extends React.Component {

    state = {
        message: "",
        messageType: ""
    };

    constructor(props) {
        super(props);
        this.import = this.import.bind(this);
    }

    import() {
        let data = $("#BuylistImport").val();
        api("PUT", "/buylists/" + this.props.buylistId + "/import", {data: data}, ({status, data}) => {
            console.log(data);
            if (status === 200) {
                this.setState({message: "Cards were successfully imported!", messageType: "success"});
                this.props.onImport();
            } else {
                this.setState({message: "Import failed. Check your data.", messageType: "danger"});
            }
        });
    }

    render() {
        let placeholder = "3 Elvish Mystic\n1 Ancient Ziggurat";
        return (
            <div className="row">
                <div className="col-md-8">
                    <textarea className="form-control" id="BuylistImport"
                              style={{height: "250px"}} placeholder={placeholder}/>
                </div>
                <div className="col-md-4">
                    <button className="btn btn-info w-100" onClick={this.import}>
                        <FAI icon="file-import" className="mr-2"/>
                        Import
                    </button>
                    {this.state.message &&
                    <div className={"mt-2 w-100 text-center alert alert-" + this.state.messageType}>
                        {this.state.message}
                    </div>}
                </div>
            </div>
        );
    }
}

BuylistImport.propTypes = {
    buylistId: PropTypes.number,
    onImport: PropTypes.func
};
