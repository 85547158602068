import {ProgressBar, Tabs} from "components/stateless";
import AppLayout from "layouts/AppLayout";
import React from "react";
import SetCards from "tabs/SetCards";
import SetImportExport from "tabs/SetImportExport";
import SetStatistics from "tabs/SetStatistics";
import {api} from "utils";

export default class Set extends React.Component {

    state = {
        activeTab: 'cards',
        set: []
    };

    componentDidMount() {
        let url = '/sets/' + this.props.match.params.code;
        api("GET", url, {}, ({status, data}) => {
            this.setState({'set': data});
        });
    }

    render() {
        if (!this.state.set || !this.state.set.code) return <ProgressBar/>;

        let entries = [
            {label: 'Cards', code: 'cards'},
            {label: 'Statistics', code: 'statistics'},
            {label: 'Import / Export', code: 'importExport'}
        ];

        return (
            <AppLayout history={this.props.history}>
                <div className="mb-2">{this.renderSetInfo()}</div>
                <Tabs entries={entries} activeTab={this.state.activeTab}
                      onTabClick={(code) => this.setState({activeTab: code})}/>
                {this.state.activeTab === 'cards' && <SetCards set={this.props.match.params.code}/>}
                {this.state.activeTab === 'statistics' && <SetStatistics set={this.props.match.params.code}/>}
                {this.state.activeTab === 'importExport' && <SetImportExport set={this.props.match.params.code}/>}
            </AppLayout>
        );
    }

    renderSetInfo() {
        return (
            <h1>
                <i className={'ss ss-rare ss-' + this.state.set.code.toLowerCase()}/>
                <span className="px-4">{this.state.set.name}</span>
            </h1>
        );
    }
}

