import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import {api, capitalize} from "utils";

export default class DeckInfo extends React.Component {

    state = {
        name: this.props.deck.name,
        format: this.props.deck.format,
        description: this.props.deck.description,
        notes: this.props.deck.notes
    };

    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    update(inputName) {
        // Check if value has changed
        let deck = this.props.deck;
        let newValue = this.state[inputName];
        if (deck[inputName] === newValue) return null;

        // Send the update
        api("PATCH", "/decks/" + deck.id, {[inputName]: newValue}, ({status, data}) => {});

        // Update the parent component
        deck[inputName] = newValue;
        this.props.onDeckUpdate(deck);
    }

    onDelete() {
        api("DELETE", '/decks/' + this.props.deck.id, {}, ({status, data}) => {
            this.props.history.push('/app/decks');
        });
    }

    render() {
        let formats = ['standard', 'modern', 'legacy', 'commander', 'pioneer', 'pauper', 'casual'];

        return (
            <div className="row">
                {/* Deck name input */}
                <div className="col-md-6">
                    <div className='form-group my-2'>
                        <label htmlFor='deckName'>Name</label>
                        <div className="d-flex">
                            <input type='text' className='form-control' id='deckName'
                                   placeholder='Deck name' value={this.state.name}
                                   onChange={e => this.setState({name: e.target.value})}
                                   onBlur={() => this.update('name')}/>
                            <button className="btn btn-danger ml-2" type="button" title="Delete this deck"
                                    onClick={this.onDelete}>
                                <FAI icon="trash"/>
                            </button>
                        </div>
                    </div>
                </div>
                {/* Deck format */}
                <div className="col-md-6">
                    <div className='form-group my-2'>
                        <label htmlFor='deckFormat'>Format</label>
                        <select className='form-control' id='deckFormat' value={this.state.format}
                                onChange={e => this.setState(
                                    {format: e.target.value},
                                    () => this.update("format")
                                )}>
                            {formats.map(f => {
                                return <option key={f} value={f}>{capitalize(f)}</option>;
                            })}
                        </select>
                        <small className='form-text text-muted'>
                            The search only retrieves cards that are legal in the chosen format.
                        </small>
                    </div>
                </div>
                {/* Deck description */}
                <div className="col-md-6">
                    <div className='form-group my-2'>
                        <label htmlFor='deckDescription'>Description</label>
                        <textarea className='form-control' id='deckDescription' value={this.state.description}
                                  placeholder="Type a description of your deck's strategy"
                                  onChange={e => this.setState({description: e.target.value})}
                                  onBlur={() => this.update('description')}/>
                    </div>
                </div>
                {/* Notes */}
                <div className="col-md-6">
                    <div className='form-group my-2'>
                        <label htmlFor='deckNotes'>Notes</label>
                        <textarea className='form-control' id='deckNotes' value={this.state.notes}
                                  placeholder="Here's a place for your thoughts and reminders"
                                  onChange={e => this.setState({notes: e.target.value})}
                                  onBlur={() => this.update('notes')}/>
                    </div>
                </div>
            </div>
        );
    }
}

DeckInfo.propTypes = {
    deck: PropTypes.object,
    history: PropTypes.object,
    onDeckUpdate: PropTypes.func
};
