import CollectionTable from "components/CollectionTable";
import SetCard from "components/SetCard";
import {Checkable, ProgressBar, RadioChoice, TextWithSymbols, Toggle} from "components/stateless";
import {imgFolder} from "config/config";
import $ from "jquery";
import React from "react";
import {api, capitalize, getCardType} from "utils";

export default class SetCards extends React.Component {

    state = {
        activeTab: 'cards',
        cards: [],
        ftSearch: '',
        rarities: {
            label: 'Rarity',
            type: 'checkables',
            list: ['common', 'uncommon', 'rare', 'mythic'],
            value: []
        },
        types: {
            label: 'Type',
            type: 'checkables',
            list: ['creature', 'sorcery', 'instant', 'enchantment', 'artifact', 'planeswalker', 'land'],
            value: []
        },
        colors: {
            label: 'Color',
            type: 'checkables',
            list: ['w', 'u', 'b', 'r', 'g'],
            value: []
        },
        copies: {
            list: [
                {name: 'present', label: 'You have at least one copy of the card in the set'},
                {name: 'missing', label: 'You don\'t have that card in every language'},
                {name: 'missing-en', label: 'You don\'t have that card in english'},
                {name: 'missing-fr', label: 'You don\'t have that card in french'},
                {name: 'playset', label: 'You have at least 4 copies of that card across all sets'},
                {name: 'no-playset', label: 'You have less than 4 copies of that card across all sets'},
                {
                    name: 'missing-or-no-playset',
                    label: 'You don\'t have that card in every language and a playset of it'
                }
            ],
            value: ''
        },
        view: {
            list: [
                {label: "List", value: "list"},
                {label: "Images", value: "images"}
            ],
            value: "list"
        }
    };

    constructor(props) {
        super(props);
        this.toggleCheckable = this.toggleCheckable.bind(this);
        this.changeChoosable = this.changeChoosable.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
    }

    componentDidMount() {
        let url = '/sets/' + this.props.set + '/cards';
        api("GET", url, {}, ({status, data}) => {
            this.setState({
                cards: data.sort((a, b) => {
                    let intComp = parseInt(a.number, 10) > parseInt(b.number, 10);
                    if (intComp !== 0) return intComp;
                    return a.number.localeCompare(b.number);
                })
            });
        });
    }

    toggleCheckable(field, value, checked) {
        let index = field.value.indexOf(value);
        if (index > -1 && !checked) field.value.splice(index, 1);
        else if (index <= -1 && checked) field.value.push(value);
        this.setState({field: field});
    }

    changeChoosable(name, value) {
        let field = this.state[name];
        if (field.value === value) field.value = "";
        else field.value = value;
        this.setState({[name]: field});
    }

    changeToggle(name, value) {
        let field = this.state[name];
        field.value = value;
        this.setState({[name]: field});
    }

    filter(cardInSet) {
        let show = true;

        // Filter by rarity
        if (show && this.state.rarities.value.length > 0) {
            if (this.state.rarities.value.indexOf(cardInSet.rarity) <= -1) show = false;
        }

        // Filter by color
        if (show && this.state.colors.value.length) {
            show = false;
            if (cardInSet.card.manaCost) {
                for (let color of this.state.colors.value) {
                    if (cardInSet.card.manaCost.toLowerCase().includes(color.toLowerCase())) show = true;
                }
            }
        }

        // Filter by type
        if (show && this.state.types.value.length) {
            show = false;
            for (let type of this.state.types.value) {
                if (cardInSet.card.type.toLowerCase().includes(type)) show = true;
            }
        }

        // Filter by copies
        if (show && this.state.copies.value) {
            show = false;
            switch (this.state.copies.value) {
                case 'present':
                    for (let c of cardInSet.collection) if (c.amount >= 1) show = true;
                    break;
                case 'missing':
                    for (let c of cardInSet.collection) if (c.amount < 1) show = true;
                    break;
                case 'missing-fr':
                    for (let c of cardInSet.collection) if (c.language === 'fr' && c.amount < 1) show = true;
                    break;
                case 'missing-en':
                    for (let c of cardInSet.collection) if (c.language === 'en' && c.amount < 1) show = true;
                    break;
                case 'playset':
                    if (cardInSet.card.total >= 4) show = true;
                    break;
                case 'no-playset':
                    if (cardInSet.card.total < 4) show = true;
                    break;
                case 'missing-or-no-playset':
                    for (let c of cardInSet.collection) if (c.amount < 1) show = true;
                    if (cardInSet.card.total < 4) show = true;
                    break;
                default:
            }
        }

        // Filter by fulltext search
        if (show && this.state.ftSearch) {
            show = false;
            let search = this.state.ftSearch.toLowerCase();
            let properties = ['name', 'type', 'text'];
            for (let p of properties) {
                let value = cardInSet.card[p];
                if (value && value.toLowerCase().includes(search)) show = true;
            }
        }
        return show;
    }

    onCardChange(cisId, language, amount) {
        let cards = this.state.cards.map(cis => {
            if (cis.id === cisId) {
                cis.collection = cis.collection.map(cic => {
                    if (cic.language === language) {
                        cic.amount = amount;
                    }
                    return cic;
                });
            }
            return cis;
        });

        this.setState({cards: cards});
    }

    render() {
        return (
            <div>
                <div className="row">
                    {this.renderFulltextSearch()}
                    {this.renderViewToggle()}
                    {this.renderRarities()}
                    {this.renderTypes()}
                    {this.renderColors()}
                    {this.renderCopies()}
                </div>

                {this.state.view.value === "images" && this.renderCards()}
                {this.state.view.value === "list" && this.renderList()}
            </div>
        );
    }

    renderFulltextSearch() {
        return (
            <div className='col-12 col-md-6 mb-3 text-center'>
                <input type='text' placeholder='Search by name, type or text'
                       className='form-control form-control-lg w-100 text-center'
                       onKeyUp={e => this.setState({ftSearch: $(e.target).val()})}/>
            </div>
        );
    }

    renderViewToggle() {
        return (
            <div className='col-12 col-md-6 mb-3 text-center'>
                <Toggle options={this.state.view.list}
                        value={this.state.view.value}
                        onToggle={value => this.changeToggle("view", value)}/>
            </div>
        );
    }

    renderRarities() {
        return (
            <div className='col-12 col-md-6 mb-3 text-center'>
                {this.state.rarities.list.map(function (r) {
                    return <Checkable key={r} checked={this.state.rarities.value.indexOf(r) > -1}
                                      imgURL={imgFolder + '/icon/rarities-' + r + '.png'}
                                      label={capitalize(r)}
                                      onToggleCheck={checked => this.toggleCheckable(this.state.rarities, r, checked)}/>;
                }, this)}
            </div>
        );
    }

    renderTypes() {
        return (
            <div className='col-12 col-md-6 mb-3 text-center'>
                {this.state.types.list.map(function (t) {
                    return <Checkable key={t} label={capitalize(t)}
                                      checked={this.state.types.value.indexOf(t) > -1}
                                      imgURL={imgFolder + '/icon/types-' + t + '.png'}
                                      onToggleCheck={checked => this.toggleCheckable(this.state.types, t, checked)}/>;
                }, this)}
            </div>
        );
    }

    renderColors() {
        return (
            <div className='col-12 col-md-6 mb-3 text-center'>
                {this.state.colors.list.map(function (c) {
                    return <Checkable key={c} checked={this.state.colors.value.indexOf(c) > -1}
                                      imgURL={imgFolder + '/icon/colors-' + c + '.png'}
                                      onToggleCheck={checked => this.toggleCheckable(this.state.colors, c, checked)}/>;
                }, this)}
            </div>
        );
    }

    renderCopies() {
        let copiesChoosables = [];
        for (let c of this.state.copies.list) {
            copiesChoosables.push({
                value: c.name,
                label: c.label,
                imgURL: imgFolder + '/icon/copies-' + c.name + '.png'
            });
        }

        return (
            <div className='col-12 col-md-6 mb-3 text-center'>
                <RadioChoice list={copiesChoosables} value={this.state.copies.value}
                             onToggleCheckable={value => this.changeChoosable("copies", value)}/>
            </div>
        );
    }

    renderCards() {
        if (!this.state.cards.length) return <ProgressBar/>;
        let filtered = this.state.cards.filter(c => this.filter(c));

        return (
            <div className='row'>
                {filtered.map(cis =>
                    <div className='col-6 col-md-4 col-lg-3 mb-3' key={cis.number}>
                        <SetCard cardInSet={cis} card={cis.card} setCode={this.props.set}
                                 onChange={(language, amount) => this.onCardChange(cis.id, language, amount)}/>
                    </div>
                )}
            </div>
        );
    }

    renderList() {
        if (!this.state.cards.length) return <ProgressBar/>;
        let filtered = this.state.cards.filter(c => this.filter(c));
        return (
            <table className='table'>
                <tbody>
                {filtered.map(cis =>
                    <tr key={cis.number}>
                        <td>#{cis.number}</td>
                        <td>
                            <img src={imgFolder + '/icon/rarities-' + cis.rarity + '.png'} width="20"
                                 alt={capitalize(cis.rarity)} title={capitalize(cis.rarity)}/>
                        </td>
                        <td>
                            <img src={imgFolder + '/icon/types-' + getCardType(cis.card.type) + '.png'}
                                 alt={capitalize(getCardType(cis.card.type))} width="20"
                                 title={capitalize(getCardType(cis.card.type))}/>
                        </td>
                        <td><TextWithSymbols text={cis.card.manaCost}/></td>
                        <td>{cis.card.name}</td>
                        {cis.collection.map(cic =>
                            <td className="p-0 align-middle" width="20%" key={cis.id + cic.language}>
                                <table className="w-auto">
                                    <tbody>
                                    <CollectionTable set={this.props.set} number={cis.number} cisId={cis.id}
                                                     language={cic.language} amount={cic.amount}
                                                     onChange={amount => this.onCardChange(cis.id, cic.language, amount)}/>
                                    </tbody>
                                </table>
                            </td>
                        )}
                    </tr>
                )}
                </tbody>
            </table>
        );
    }
}