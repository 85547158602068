import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React from "react";
import "styles/MultiList.css";

export default class MultiList extends React.Component {

    state = {
        suggested: [],
        suggestedIndex: 0,
        input: ''
    };

    constructor(props) {
        super(props);
        this.updateInput = this.updateInput.bind(this);
        this.handleControls = this.handleControls.bind(this);
        this.addSuggested = this.addSuggested.bind(this);
        this.removeSelected = this.removeSelected.bind(this);
    }

    updateInput(e) {
        // Update suggestions
        let val = $(e.target).val();
        let suggestions = this.props.list
            .filter(v => v.label.toLowerCase().startsWith(val.toLowerCase()))
            .filter(v => {
                let exists = this.props.selected.find(s => s.label === v.label);
                return typeof exists === 'undefined';
            })
            .sort((a, b) => a.label > b.label ? 1 : -1);
        if (suggestions.length && val) {
            this.setState({
                suggested: suggestions,
                suggestedIndex: 0,
                input: suggestions[0].label.substring(0, val.length) || val
            });
        } else this.setState({suggested: '', input: val});
    }

    handleControls(e) {
        let key = e.keyCode;
        // On Enter
        if (key === 13) this.addSuggested();

        // On Up / Down arrows
        else if (key === 38 || key === 40) {
            let length = this.state.suggested.length;
            let index = key === 40 ? this.state.suggestedIndex + 1 : this.state.suggestedIndex - 1;
            if (index < 0) index = length - 1;
            if (index > (length - 1)) index = 0;
            this.setState({suggestedIndex: index});
        }
    }

    addSuggested() {
        let suggested = this.state.suggested[this.state.suggestedIndex];
        if (suggested) {
            let available = this.props.list;
            let selected = this.props.selected;
            selected.push(available.find(e => e === suggested));
            this.setState({suggested: [], suggestedIndex: 0, input: ''});
            this.props.onSelectionChange(selected);
        }
    }

    removeSelected(label) {
        let selected = this.props.selected;
        selected = selected.filter(e => e.label !== label);
        this.props.onSelectionChange(selected);
    }

    render() {
        return (
            <div className='MultiList form-control p-0 d-flex flex-wrap justify-content-start align-items-center w-100'
                 onClick={e => $(e.target).find('.Input').focus()} onFocus={e => $(e.target).find('.Input').focus()}>
                {this.props.selected.map(option => this.renderBadge(option))}
                {this.renderInput()}
            </div>
        );
    }

    renderBadge(option) {
        return (
            <h4 className="d-inline" key={option.label}>
                <span className='badge badge-secondary p-1 m-1 pointer'
                      onClick={() => this.removeSelected(option.label)}>
                    <div className='d-flex align-items-center'>
                        <span>{option.label}</span>
                        <FAI icon="times ml-1"/>
                    </div>
                </span>
            </h4>
        );
    }

    renderInput() {
        let suggested = this.state.suggested;
        let textColor = (!suggested.length && this.state.input) ? ' text-danger' : '';
        let suggestedLabel = suggested.length ? suggested[this.state.suggestedIndex].label : '';
        let placeholder = !this.props.selected.length ? this.props.placeholder : '';
        return (
            <div className='Search m-1'>
                <input type='text' className='border-0 bg-transparent text-secondary Suggestion'
                       value={suggestedLabel} readOnly={true}/>
                <input type='text' className={'border-0 bg-transparent Input' + textColor} value={this.state.input}
                       onChange={this.updateInput} onKeyUp={this.handleControls} placeholder={placeholder}/>
            </div>
        );
    }
}
