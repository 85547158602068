import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import {imgFolder} from "config/config";
import PropTypes from 'prop-types';
import React from "react";
import {api} from "utils";

export default class CollectionTable extends React.Component {

    changeAmount(add) {
        let amount = this.props.amount;
        let amountChanged = false;
        if (add) {
            amount++;
            amountChanged = true;
        } else if (!add && amount > 0) {
            amount--;
            amountChanged = true;
        }

        if (amountChanged) {
            this.props.onChange(amount);

            // Send the update request
            let form = {
                language: this.props.language,
                amount: amount
            };
            api("PUT", '/collection/' + this.props.cisId, form, ({status, data}) => {});
        }
    }

    render() {
        let style = {
            backgroundColor: "rgba(255,255,255,0.4)"
        };

        return (
            <tr style={style} className="p-0">
                <td className="w-25 border-0 text-center p-1">
                    <img src={imgFolder + "/icon/lang-" + this.props.language + ".png"}
                         alt={this.props.language} className="img-fluid"/>
                </td>
                <td className="w-25 border-0 text-center p-1 align-middle pointer"
                    onClick={() => this.changeAmount(false)}>
                    <FAI icon="minus"/>
                </td>
                <td className="w-25 border-0 text-center p-1 align-middle">
                    {this.props.amount}
                </td>
                <td className="w-25 border-0 text-center p-1 align-middle pointer"
                    onClick={() => this.changeAmount(true)}>
                    <FAI icon="plus"/>
                </td>
            </tr>
        );
    }
}

CollectionTable.propTypes = {
    cisId: PropTypes.number,
    set: PropTypes.string,
    number: PropTypes.string,
    language: PropTypes.string,
    amount: PropTypes.number
};
