import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import CardImage from "components/CardImage";
import {PlusMinus, ProgressBar, Tabs} from "components/stateless";
import $ from "jquery";
import React from "react";
import "styles/Buylist.css";
import BuylistImport from "tabs/BuylistImport";
import {api} from "utils";

export default class Buylist extends React.Component {

    state = {
        buylist: null,
        cards: [],
        searchResults: [],
        activeTab: 'buylist',
        selected: [],
        hoveredCard: null
    };

    constructor(props) {
        super(props);
        this.getBuylist = this.getBuylist.bind(this);
        this.search = this.search.bind(this);
        this.onNameInputChange = this.onNameInputChange.bind(this);
        this.onNameFocusLoss = this.onNameFocusLoss.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        this.getBuylist();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) this.getBuylist();
    }

    getBuylist() {
        this.setState({buylist: null});
        api("GET", '/buylists/' + this.props.id, {}, ({status, data}) => {
            this.setState({cards: data.cards});
            delete data.cards;
            this.setState({buylist: data});
        });
    }

    search(e) {
        let val = $(e.target).val();
        if (val.length >= 3) {
            let form = {
                form: [
                    {name: 'name', value: val}
                ],
                orderBy: {
                    value: 'name',
                    order: 'asc'
                }
            };
            api("GET", '/cards', form, ({status, data}) => {
                if (data && data.length > 0) this.setState({searchResults: data.slice(0, 10)});
                else this.setState({searchResults: []});
            });
        }
    }

    changeAmount(card, amount) {
        // Change the state
        let cards = this.state.cards;
        let newCards;
        if (amount === 0) newCards = cards.filter((cib) => cib.card.id !== card.id);
        else {
            let cardInList = false;
            newCards = cards.map((cib) => {
                if (cib.card.id === card.id) {
                    cib.amount = amount;
                    cardInList = true;
                }
                return cib;
            });
            if (!cardInList) newCards.push({card: card, amount: amount});
        }

        this.setState({
            cards: newCards,
            searchResults: []
        });
        $('#CardSearch input').val('');

        // Send the request
        let url = '/buylists/' + this.state.buylist.id + '/cards/' + card.id;
        api("PATCH", url, {amount: amount}, ({status, data}) => {
        });
    }

    onNameInputChange(e) {
        let buylist = this.state.buylist;
        buylist.name = $(e.target).val();
        this.setState({buylist: buylist});
    }

    onNameFocusLoss() {
        if (!this.state.buylist.name) return null;
        this.props.onNameChange(this.state.buylist.name);

        let url = '/buylists/' + this.state.buylist.id;
        api("PATCH", url, {name: this.state.buylist.name}, ({status, data}) => {
        });
    }

    onDelete() {
        api("DELETE", '/buylists/' + this.state.buylist.id, {}, ({status, data}) => {
        });
        this.props.onDelete();
    }

    toggleSelect(id, checked) {
        let sel = this.state.selected;
        let cids = this.state.cards;

        // Select or deselect all
        if (!id) {
            if (!checked) sel = [];
            else sel = cids.map(s => s.id);
        }
        // Select or deselect one
        else {
            if (!checked) sel = sel.filter(s => s !== id);
            else {
                if (typeof sel.find(s => s === id) === 'undefined') sel.push(id);
            }
        }

        this.setState({selected: sel});
    }

    render() {
        if (!this.state.buylist) return <ProgressBar/>;

        let tabs = [
            {label: 'Buylist', code: 'buylist'},
            {label: 'Export', code: 'export'},
            {label: 'Import', code: 'import'}
        ];

        return (
            <div>
                <div className="mb-3">{this.renderBuylistInfo()}</div>
                <Tabs entries={tabs} activeTab={this.state.activeTab}
                      onTabClick={code => this.setState({activeTab: code})}/>

                {this.state.activeTab === 'buylist' && this.renderBuylistTab()}
                {this.state.activeTab === 'export' && this.renderExportTab()}
                {this.state.activeTab === 'import' &&
                <BuylistImport buylistId={this.state.buylist.id}
                               onImport={this.getBuylist}/>}
            </div>
        );
    }

    renderBuylistInfo() {
        return (
            <div className="d-flex justify-content-start">
                <input type="text" className="form-control form-control-lg" placeholder="Your buylist's name"
                       value={this.state.buylist.name} onChange={this.onNameInputChange} onBlur={this.onNameFocusLoss}/>
                <button className="btn btn-danger ml-2" type="button" title="Delete" onClick={this.onDelete}>
                    <FAI icon="trash"/>
                </button>
            </div>
        );
    }

    renderBuylistTab() {
        return (
            <div>
                {this.renderSearchBar()}
                {this.renderCardsList()}
            </div>
        );
    }

    renderExportTab() {
        return (
            <div className="rounded border border-secondary p-4 bg-light">
                {this.state.cards.map(cib =>
                    <span className="d-block" key={cib.id}>{cib.amount + " " + cib.card.name}</span>
                )}
            </div>
        );
    }

    renderSearchBar() {
        return (
            <div id='CardSearch'>
                <input type='text' className='form-control' placeholder='Search for a card...'
                       onKeyUp={this.search}/>
                <ul className='list-group'>
                    {this.state.searchResults.map(function (c) {
                        return (
                            <li className='list-group-item py-1 pointer' key={c.id}
                                onClick={() => this.changeAmount(c, 1)}>
                                {c.name}
                            </li>
                        );
                    }, this)}
                </ul>
            </div>
        );
    }

    renderCardsList() {
        return (
            <table className='table table-sm' id='BuylistCards'>
                <thead>
                <tr>
                    <th><input type="checkbox" onChange={e => this.toggleSelect(null, e.target.checked)}/></th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.state.cards.map(function (c) {
                    return (
                        <tr key={c.card.id}>
                            <td>
                                <div className="HoverImage">
                                    <CardImage name={c.card.name} set={c.card.lastPrint.set}
                                               number={c.card.lastPrint.number}/>
                                </div>
                                <input type="checkbox" onChange={e => this.toggleSelect(c.id, e.target.checked)}
                                       checked={typeof this.state.selected.find(s => s === c.id) !== 'undefined'}/>
                            </td>
                            <td>
                                {c.card.name}
                            </td>
                            <td>
                                <PlusMinus
                                    amount={c.amount}
                                    onAmountChange={(amount) => this.changeAmount(c.card, amount)}/>
                            </td>
                            <td>
                                <button className='btn btn-danger btn-sm'
                                        onClick={() => this.changeAmount(c.card, 0)}>
                                    <FAI icon="times"/>
                                </button>
                            </td>
                        </tr>
                    );
                }, this)}
                </tbody>
            </table>
        );
    }
}

