import Chart from "chart.js";
import React from "react";

export default class DeckStats extends React.Component {

    charts = {
        colorChart: null,
        typeChart: null,
        manaCostChart: null
    };

    componentDidMount() {
        this.showAllCharts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) this.showAllCharts();
    }

    showAllCharts() {
        this.showColorChart();
        this.showTypeChart();
        this.showCMCChart();
    }

    showColorChart() {
        let cards = this.props.cards.filter(cid => !cid.sideboard);
        let colors = [
            {
                name: "White",
                code: "W",
                color: "200,200,200",
                amount: 0
            },
            {
                name: "Blue",
                code: "U",
                color: "77,208,225",
                amount: 0
            },
            {
                name: "Black",
                code: "B",
                color: "97,97,97",
                amount: 0
            },
            {
                name: "Red",
                code: "R",
                color: "198,40,40",
                amount: 0
            },
            {
                name: "Green",
                code: "G",
                color: "139,195,74",
                amount: 0
            }
        ];

        colors = colors.map((color) => {
            for (let cid of cards) {
                if (cid.card.manaCost && cid.card.manaCost.includes(color.code)) {
                    let devotion = cid.card.manaCost.split(color.code).length - 1;
                    color.amount += cid.amount * devotion;
                }
            }
            return color;
        });

        let data = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }]
        };

        for (let color of colors) {
            if (color.amount > 0) {
                data.labels.push(color.name);
                data.datasets[0].data.push(color.amount);
                data.datasets[0].backgroundColor.push('rgba(' + color.color + ',0.3)');
                data.datasets[0].borderColor.push('rgba(' + color.color + ',0.3)');
            }
        }

        this.displayChart('colorChart', 'pie', data);
    }

    showTypeChart() {
        let cards = this.props.cards.filter(cid => !cid.sideboard);
        let types = [
            {
                name: "Land",
                color: "139,195,74",
                amount: 0
            },
            {
                name: "Creature",
                color: "198,40,40",
                amount: 0
            },
            {
                name: "Instant",
                color: "77,208,225",
                amount: 0
            },
            {
                name: "Sorcery",
                color: "0,151,167",
                amount: 0
            },
            {
                name: "Enchantment",
                color: "220,220,220",
                amount: 0
            },
            {
                name: "Artifact",
                color: "97,97,97",
                amount: 0
            },
            {
                name: "Planeswalker",
                color: "253,216,53",
                amount: 0
            }
        ];

        types = types.map((type) => {
            for (let cid of cards) {
                if (cid.card.type.toLowerCase().includes(type.name.toLowerCase())) {
                    type.amount += cid.amount;
                }
            }
            return type;
        });

        let data = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }]
        };

        for (let type of types) {
            if (type.amount > 0) {
                data.labels.push(type.name);
                data.datasets[0].data.push(type.amount);
                data.datasets[0].backgroundColor.push('rgba(' + type.color + ',0.3)');
                data.datasets[0].borderColor.push('rgba(' + type.color + ',1)');
            }
        }

        this.displayChart('typeChart', 'pie', data);
    }

    showCMCChart() {
        let cards = this.props.cards.filter(cid => !cid.sideboard);
        let colorGradient = ["135,229,0", "169,222,0", "200,216,0", "210,189,0", "203,149,0", "197,110,0", "191,75,0"];
        let amounts = [0, 0, 0, 0, 0, 0, 0];

        for (let cid of cards) {
            if (!cid.card.type.toLowerCase().includes('land')) {
                let cmc = cid.card.cmc < 6 ? cid.card.cmc : 6;
                amounts[cmc] += cid.amount;
            }
        }

        let data = {
            labels: ['0', '1', '2', '3', '4', '5', '6+'],
            datasets: [{
                data: amounts,
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }]
        };

        for (let color of colorGradient) {
            data.datasets[0].backgroundColor.push('rgba(' + color + ', 0.3)');
            data.datasets[0].borderColor.push('rgba(' + color + ', 1)');
        }

        this.displayChart('manaCostChart', 'bar', data);
    }

    displayChart(id, type, data) {
        if (this.charts[id] === null) {
            this.charts[id] = new Chart(id, {
                type: type,
                data: data,
                options: {
                    legend: {
                        display: false
                    }
                }
            });
        } else {
            this.charts[id].data = data;
            this.charts[id].update();
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        {this.renderCardsTotal()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <canvas id="colorChart"></canvas>
                    </div>
                    <div className="col-md-4">
                        <canvas id="typeChart"></canvas>
                    </div>
                    <div className="col-md-4">
                        <canvas id="manaCostChart"></canvas>
                    </div>
                </div>
            </div>
        );
    }

    renderCardsTotal() {
        let cards = this.props.cards;
        let mainTotal = 0;
        let sbTotal = 0;
        for (let cid of cards) {
            if(cid.sideboard) sbTotal += cid.amount;
            else mainTotal += cid.amount;
        }

        return <span>Main deck: {mainTotal} | Sideboard: {sbTotal}</span>;
    }
}
