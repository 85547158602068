import AdvSearchForm from "components/AdvSearchForm";
import {SearchCard} from "components/stateless";
import AppLayout from "layouts/AppLayout";
import React from "react";

export default class AdvancedSearch extends React.Component {

    state = {
        cards: ''
    };

    render() {
        return (
            <AppLayout history={this.props.history}>
                <AdvSearchForm onCardsChange={cards => this.setState({cards: cards})}/>
                <hr/>
                {this.renderCards()}
            </AppLayout>
        );
    }

    renderCards() {
        return (
            <div className='row'>
                {!Array.isArray(this.state.cards) ?
                    <div className='col-12 my-3 text-center'>
                        <h3>{this.state.cards}</h3>
                    </div>
                    :
                    this.state.cards.map(function (c) {
                        return (
                            <div className='col-6 col-md-4 col-lg-3 mb-3' key={c.id}>
                                <SearchCard card={c} history={this.props.history}/>
                            </div>
                        );
                    }, this)
                }
            </div>
        );
    }
}


