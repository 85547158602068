import {apiUrl} from "config/config";
import $ from "jquery";

export function capitalize(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.substr(1);
}

export function timeSince(date) {
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return interval + ' years';

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return interval + ' months';

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return interval + ' days';

    interval = Math.floor(seconds / 3600);
    if (interval > 1) return interval + ' hours';

    interval = Math.floor(seconds / 60);
    if (interval > 1) return interval + ' minutes';

    return Math.floor(seconds) + ' seconds';
}

export function getNewAmount(int, symbol) {
    int = parseInt(int, 10);
    if (symbol === '+') return int + 1;
    if (symbol === '-') return int > 0 ? int - 1 : 0;
    if (symbol === 'x') return 0;
    return null;
}

export function normalize(input) {
    let string = input.toLowerCase();

    // Caractères à remplacer par des _
    string = string.replace(/ /g, '_');
    string = string.replace(/'/g, '_');

    // Caractères à supprimer
    string = string.replace(/!/g, '');
    string = string.replace(/:/g, '');
    string = string.replace(/,/g, '');
    string = string.replace(/"/g, '');
    string = string.replace(/]/g, '');
    string = string.replace(/}/g, '');
    string = string.replace(/-/g, '');
    string = string.replace(/%/g, '');
    string = string.replace(/&/g, '');
    string = string.replace(/=/g, '');
    string = string.replace(/\?/g, '');
    string = string.replace(/\(/g, '');
    string = string.replace(/\)/g, '');
    string = string.replace(/\./g, '');
    string = string.replace(/\[/g, '');
    string = string.replace(/\{/g, '');
    string = string.replace(/\+/g, '');
    string = string.replace(/\*/g, '');
    string = string.replace(/\//g, '');
    string = string.replace(/\\/g, '');

    string = string.replace(/____/g, '_');
    string = string.replace(/___/g, '_');
    string = string.replace(/__/g, '_');

    // Lettres spéciales
    string = string.replace(/ä/g, 'a');
    string = string.replace(/à/g, 'a');
    string = string.replace(/â/g, 'a');
    string = string.replace(/å/g, 'a');
    string = string.replace(/é/g, 'e');
    string = string.replace(/è/g, 'e');
    string = string.replace(/ë/g, 'e');
    string = string.replace(/ê/g, 'e');
    string = string.replace(/î/g, 'i');
    string = string.replace(/ï/g, 'i');
    string = string.replace(/ö/g, 'o');
    string = string.replace(/ô/g, 'o');
    string = string.replace(/ø/g, 'o');
    string = string.replace(/ù/g, 'u');
    string = string.replace(/û/g, 'u');
    string = string.replace(/ü/g, 'u');
    string = string.replace(/æ/g, 'ae');
    string = string.replace(/œ/g, 'oe');
    string = string.replace(/ç/g, 'c');
    string = string.replace(/ÿ/g, 'y');

    return string;
}

export function orderBy(array, prop, ascending = true) {
    array.sort((a, b) => {
        return ascending ? a[prop] > b[prop] : a[prop] < b[prop];
    });
    return array;
}

export function brightenColor(hex, amount) {
    let color = {
        r: parseInt(hex.substr(0, 2), 16),
        g: parseInt(hex.substr(2, 2), 16),
        b: parseInt(hex.substr(4, 2), 16)
    };

    for (let c in color) {
        color[c] = color[c] + amount;
        if (color[c] > 255) color[c] = 255;
        color[c] = color[c].toString(16);
    }
    return color.r + color.g + color.b;
}

export function download(filename, text, dataType) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:' + dataType + ';charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function api(method, url, form = [], callback) {
    $.ajax({
        method: method,
        url: apiUrl + url,
        data: form
    })
        .done((data, textStatus, jqXHR) => {
            callback({
                status: jqXHR.status,
                data: data
            });
        })
        .fail((jqXHR, textStatus, errorThrown) => {
            if (jqXHR.status === 401) {
                localStorage.removeItem("authKey");
                window.location.reload();
            }
            callback({
                status: jqXHR.status,
                data: null
            });
        });
}

export function getCardType(typeLine) {
    let types = ['creature', 'sorcery', 'instant', 'enchantment', 'artifact', 'planeswalker', 'land'];
    for (let type of types) {
        if (typeLine.toLowerCase().includes(type)) return type;
    }
    return null;
}