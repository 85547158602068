import {imgFolder} from "config/config";
import $ from "jquery";
import React from "react";
import {Link, Redirect} from "react-router-dom";
import {api} from "utils";

export default class Login extends React.Component {

    state = {
        authKey: localStorage.authKey,
        loginError: null
    };

    constructor(props) {
        super(props);
        this.keyDown = this.keyDown.bind(this);
        this.login = this.login.bind(this);
    }

    keyDown(e) {
        if (e.keyCode === 13) this.login();
    }

    login() {
        let form = {
            email: $('#email').val(),
            password: $('#password').val()
        };

        api("POST", "/login", form, ({status, data}) => {
            if (status !== 200) this.setState({loginError: data.error});
            else {
                localStorage.setItem("authKey", data.authKey);
                this.setState({authKey: data.authKey});
            }
        });
    }

    render() {
        if (this.state.authKey) {
            return <Redirect to='/app/advanced-search'/>;
        }

        let style = {textAlign: 'center'};

        return (
            <div className='row'>
                <form className='col-12 col-md-6 m-auto'>
                    <img src={imgFolder + '/logo.png'} className='img-fluid mt-3 mb-5' alt='MtgManager'/>
                    <input type='text' id='email' className='form-control' placeholder='Email'
                           onKeyDown={this.keyDown} style={style}/>
                    <input type='password' id='password' className='form-control my-2' placeholder='Password'
                           onKeyDown={this.keyDown} style={style}/>
                    <button type='button' className='btn btn-info w-100' id='logIn' onClick={this.login}>
                        Log in
                    </button>
                    <p className="text-muted my-2">No account yet? <Link to="/signup">Sign up!</Link></p>
                    {this.state.loginError ?
                        <div className='alert alert-warning mt-3' role='alert'>
                            {this.state.loginError}
                        </div>
                        : null}
                </form>
            </div>
        );
    }
}
