import {library} from "@fortawesome/fontawesome-svg-core";

import {faChartPie, faFileExport, faFileImport, faInfoCircle, faSearch} from "@fortawesome/pro-light-svg-icons";

library.add(
    faInfoCircle,
    faSearch,
    faChartPie,
    faFileExport,
    faFileImport
);