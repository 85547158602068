import CardStats from "components/CardStats";
import SetStats from "components/SetStats";
import {Tabs, Toggle} from "components/stateless";
import AppLayout from "layouts/AppLayout";
import React from "react";

export default class Statistics extends React.Component {

    state = {
        activeTab: 'cards',
        display: 'amount',
        amount: '1'
    };

    constructor(props) {
        super(props);
        this.changeParam = this.changeParam.bind(this);
    }

    changeParam(label, value) {
        if (this.state[label] === value) return null;
        this.setState({[label]: value});
    }

    render() {
        let entries = [
            {label: 'Cards', code: 'cards'},
            {label: 'Prints', code: 'prints'},
        ];

        return (
            <AppLayout history={this.props.history}>
                <Tabs entries={entries} activeTab={this.state.activeTab}
                      onTabClick={(code) => this.setState({activeTab: code})}/>
                <div className="row">
                    <div className="col-12">
                        {this.renderDisplayOptions()}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        {this.state.activeTab === 'cards' ?
                            <CardStats display={this.state.display} amount={this.state.amount}/>
                            :
                            <SetStats display={this.state.display} amount={this.state.amount}/>
                        }
                    </div>
                </div>
            </AppLayout>
        );
    }

    renderDisplayOptions() {
        let formData = {
            display: [
                {label: 'percentage', value: 'percentage'},
                {label: 'amount', value: 'amount'}
            ],
            amount: [
                {label: 'copy', value: '1'},
                {label: 'playset', value: '4'}
            ]
        };

        return (
            <div className="d-flex justify-content-start align-items-center">
                <div className="mr-3">
                    <Toggle options={formData.display} value={this.state.display}
                            onToggle={(value) => this.changeParam("display", value)}/>
                </div>
                <span className="mr-3">of cards I have a</span>
                <div className="mr-3">
                    <Toggle options={formData.amount} value={this.state.amount}
                            onToggle={(value) => this.changeParam("amount", value)}/>
                </div>
                <span>of</span>
            </div>
        );
    }
}