import PropTypes from "prop-types";
import React from "react";

export default class DeckExport extends React.Component {

    render() {
        let content = "";
        for (let cid of this.props.cards) {
            content += cid.amount + " " + cid.card.name + "\n";
        }

        return (
            <textarea value={content} readOnly className="form-control" style={{height: "250px"}}/>
        );
    }
}

DeckExport.propTypes = {
    cards: PropTypes.object
};
