import {library} from "@fortawesome/fontawesome-svg-core";

import {
    faBars,
    faBoxes,
    faCaretDown,
    faChartPie,
    faCopy,
    faDollarSign,
    faDownload,
    faFileExport,
    faFileImport,
    faMinus,
    faPlus,
    faSearch,
    faSignOutAlt,
    faTimes,
    faTrash,
    faTrashAlt
} from "@fortawesome/pro-solid-svg-icons";

library.add(
    faSearch,
    faCopy,
    faChartPie,
    faBoxes,
    faDollarSign,
    faSignOutAlt,
    faTrash,
    faTimes,
    faCaretDown,
    faTrashAlt,
    faMinus,
    faPlus,
    faBars,
    faDownload,
    faFileImport,
    faFileExport
);