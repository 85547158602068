import ClickableTable from "components/ClickableTable";
import {ProgressBar} from "components/stateless";
import $ from "jquery";
import AppLayout from "layouts/AppLayout";
import React from "react";
import {api, capitalize} from "utils";

export default class Decks extends React.Component {

    state = {
        decks: null
    };

    constructor(props) {
        super(props);
        this.getDecks = this.getDecks.bind(this);
        this.createNewDeck = this.createNewDeck.bind(this);
        this.deleteDeck = this.deleteDeck.bind(this);
    }

    componentDidMount() {
        this.getDecks();
    }

    getDecks() {
        api("GET", '/decks/', {}, ({status, data}) => {
            if (!data.length) this.setState({decks: []});
            else this.setState({decks: this.colorsSyntax(data)});
        });
    }

    colorsSyntax(decks) {
        return decks.map((deck) => {
            let colors = '';
            for (let c in deck.colors) colors += '{' + deck.colors[c] + '}';
            deck.colors = colors;
            return deck;
        });
    }

    createNewDeck() {
        let name = $('#NewDeckName').val();
        let format = $('#NewDeckFormat').val();
        if (name) {
            api("POST", '/decks', {name: name, format: format}, ({status, data}) => {
                this.getDecks();
                $('#NewDeckName').val('');
            });
        }
    }

    deleteDeck(id) {
        // Change the state
        let decks = this.state.decks;
        let object = decks.find(obj => obj.id === id);
        decks.slice(decks.indexOf(object), 1);
        this.setState({deck: decks});

        // Send the request
        api("DELETE", '/decks/' + id, {}, ({status, data}) => {});
    }

    render() {
        let headers = [
            {label: 'Colors', name: 'colors', type: 'symbols', sortable: false},
            {label: 'Name', name: 'name', type: 'text', sortable: true},
            {label: 'Format', name: 'format', type: 'capitalize', sortable: true},
            {label: 'Last updated', name: 'lastUpdate', type: 'timeSince', sortable: true}
        ];
        return (
            <AppLayout history={this.props.history}>
                <div className='row'>
                    <div className='col-12'>
                        {this.renderForm()}
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-12'>
                        {!this.state.decks ? <ProgressBar/> :
                            !this.state.decks.length ? <h1 className="text-center">Create your first deck!</h1> :
                                <ClickableTable data={this.state.decks} headers={headers} sortBy='lastUpdate'
                                                ascending={false} baseURL='/app/decks/' delete={false}
                                                onDelete={id => this.deleteDeck(id)}/>
                        }
                    </div>
                </div>
            </AppLayout>
        );
    }

    renderForm() {
        let formats = ['standard', 'modern', 'legacy', 'commander', 'pioneer', 'pauper', 'casual'];
        return (
            <div className='row mb-4'>
                <div className='col-md-4'>
                    <input type='text' className='form-control' id='NewDeckName' placeholder="New deck's name"/>
                </div>
                <div className='col-md-4 my-2 my-md-auto'>
                    <select className='form-control' id='NewDeckFormat'>
                        {formats.map((f) => {
                            return <option key={f} value={f}>{capitalize(f)}</option>
                        })}
                    </select>
                </div>
                <div className='col-md-4'>
                    <button type='button' className='btn btn-info w-100' onClick={this.createNewDeck}>
                        Create a new deck
                    </button>
                </div>
            </div>
        );
    }
}