export const colors = {
    color: {
        w: 'fffdd8',
        u: 'abe1fa',
        b: 'cec2c2',
        r: 'fcac8f',
        g: '9cd4af',
        m: 'c7bfea',
        c: 'e2e2e2'
    },
    rarity: {
        c: '3a3738',
        u: '707883',
        r: 'a58e4a',
        m: 'bf4427',
    },
    type: {
        c: 'dc4000',
        l: 'a5dc31',
        e: 'f0f0d3',
        a: 'bfbbbe',
        p: 'd5ba18',
        s: '3692bf',
        i: '94e3cf',
        o: 'a197bf'
    }
};