import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React from "react";
import {GlobalHotKeys} from "react-hotkeys";
import {Link} from "react-router-dom";
import "styles/Nav.css";

export default class Nav extends React.Component {

    entries = [
        {
            'url': '/app/advanced-search',
            'title': 'Advanced search',
            'icon': 'search'
        },
        {
            'url': '/app/collection',
            'title': 'Collection',
            'icon': 'copy'
        },
        {
            'url': '/app/statistics',
            'title': 'Statistics',
            'icon': 'chart-pie'
        },
        {
            'url': '/app/decks',
            'title': 'Decks',
            'icon': 'boxes'
        },
        {
            'url': '/app/buylists',
            'title': 'Buy lists',
            'icon': 'dollar-sign'
        },
        {
            'url': '/logout',
            'title': 'Log out',
            'icon': 'sign-out-alt'
        }
    ];

    state = {
        entries: this.entries,
        open: false
    };

    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.focusOnSearch = this.focusOnSearch.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    search(e) {
        let value = $(e.target).val();
        if (e.keyCode === 27) this.closeMenu(e);
        if (e.keyCode === 13 && value) {
            this.setState({open: false});
            $(e.target).val('');
            this.props.history.push('/app/simple-search/' + value);
        }
    }

    focusOnSearch(event) {
        event.preventDefault();
        this.setState({open: true});
        $("#NavSearch input").focus();
    }

    closeMenu(event) {
        event.preventDefault();
        this.setState({open: false});
    }

    render() {
        return (
            <nav id='Nav' className={this.state.open ? 'open' : 'closed'}>
                <GlobalHotKeys keyMap={{FULLTEXT_SEARCH: "f", CLOSE_MENU: "Escape"}}
                               handlers={{FULLTEXT_SEARCH: this.focusOnSearch, CLOSE_MENU: this.closeMenu}}/>
                <p className='m-3 pointer' id='NavButton' onClick={() => this.setState({open: !this.state.open})}>
                    <FAI icon="bars"/>
                </p>
                <div className='m-3' id='NavSearch'>
                    <input className='form-control' type='text' placeholder='Search for a card' autoFocus
                           onKeyUp={(event) => this.search(event)}/>
                </div>
                <ul className='nav flex-column'>
                    {this.state.entries.map(t =>
                        <li className='NavEntry nav-item pointer w-100' key={t.title}>
                            <Link to={t.url} className='nav-link'
                                  onClick={() => this.setState({open: false})}>
                                <FAI icon={t.icon} className="text-center mr-2"/>
                                <span className="text-light">{t.title}</span>
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
        );
    }
}
